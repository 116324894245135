$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$font-size-xs:                ($font-size-base * .75);

$black:    #000 !default;
$gray-500: #adb5bd !default;
$gray-aaa: #AAAAAA !default;
$blue:    rgb(55,132,196) !default;

/* In your CSS */
.inner-html {
   width: 100%;
}

.inner-html img {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-height: 1280px;
    object-fit: contain;
 }

.inner-html p {
    font-size: 1rem;
    line-height: 1.7;
    color: #000;
    font-family: "Poppins",sans-serif;
}

.inner-html h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #000;
    font-family: "Poppins",sans-serif;
}

.inner-html h2 strong {
    font-weight: 600;
}

.inner-html h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #000;
    font-family: "Poppins",sans-serif;
}

.inner-html h3 strong {
    font-weight: 600;
}

.inner-html h4 {
    font-size: .9375rem;
    font-weight: 600;
    color: #000;
    font-family: "Poppins",sans-serif;
}

.inner-html h4 strong {
    font-weight: 600;
}

.inner-html ul {
    line-height: 1.7;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
    margin-bottom: 10px;
}
.inner-html ol {
    line-height: 1.7;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 20px;
    margin-bottom: 10px;
}

.inner-html blockquote {
    border-left: 5px solid rgb(79,131,190);
    margin-left: 0;
    margin-right: 0;
    margin-top: .6em;
    overflow: hidden;
    padding-top: .6em;
    padding-bottom: .6em;
    padding-left: .8em;
    padding-right: .8em;
}

.inner-html blockquote p {
    color: #000;
    font-weight: 400;
    font-size: .8rem;
}

.inner-html table {
    border-width: 1px;
    border: 1px solid #b3b3b3;
    border-collapse: collapse;
    border-spacing: 0;
    align-items: center;
}

.inner-html thead {
    background-color: rgb(242,242,242);
}

.inner-html th {
    padding: 1em;
    text-align: left;
}

.inner-html td {
    border-width: 1px;
    border: 1px solid #bfbfbf;
    border-collapse: collapse;
    border-spacing: 0;
    padding: .5em;
    min-width: 2em;
}
.inner-html table td, table th {
    font-size: .8125rem;
    white-space: normal !important;
}
.inner-html table thead th {
    font-size: .65rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.inner-html iframe {
    width: 100%;
    height: 50vw;
}
  