// TODO: STICKY RANKING LAYOUT ON THE RIFHT
// TODO: MOBILE RESPONSIVEL
// VOTE BUTTON 

.video_title {
  font-size: 1rem;
  margin-bottom: 0;
  color: #C0924E;
  font-weight: 600;
}

.video_description {
  font-size: 1rem;
  margin-bottom: 0;
  color: white;
  font-weight: normal;
}


.video_vote {
  font-size: 1rem;
  color: #C0924E;
  flex-shrink: 0;
}



.btn_golden {
    font-family: GildaDisplay;
    text-transform: uppercase;
    background: transparent linear-gradient(90deg, #62480D 0%, #CCAD63 100%) 0% 0% no-repeat padding-box;
    border-width: 0;
    font-weight: normal;
    color:white;
}

.btn_golden_outline {
  background-color: transparent;
  border-color: #CCAD63;
  border-width: 1px;
  font-weight: normal;
  color:#CCAD63;

  &:hover {

    border-color: black;
    background: transparent linear-gradient(90deg, #62480D 0%, #CCAD63 100%) 0% 0% no-repeat padding-box;
    color: white;
  }
}

.pulsing {
  -moz-animation: pulse 2s infinite;
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  box-shadow: 0 0 0 0 rgba(204, 173, 99,1);
}


@keyframes pulse {
  0% {
      box-shadow: 0 0 0 0 rgba(204, 173, 99,1);
  }
  70% {
      box-shadow: 0 0 0 10px transparent;
  }
  100% {
      box-shadow: 0 0 0 50px transparent;
  }
}

.hero_container {

  display: flex;
  flex-direction: row;
  background-position: right;
  background-color: black;
  max-width: 1220px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
    flex-direction: column;
  }

}


.hero_background {
  margin-left: 40px;
  @media (max-width: 991px) {
    margin-left: 5px;
    backdrop-filter: blur(2px) brightness(0.7);
  }
}


.video_card {
  &:hover {
    // zoom animation on hover for background image
    .video_thumbnail {
      transform: scale(1.05);
      transition: transform 0.5s;
    }
  }
}

.video_thumbnail {
  transition: transform 0.5s;
}

.shining {
	position: relative;
	width: 50px;
	height: 266px;
	background: linear-gradient(90deg, transparent 0%, #ffb814 10%,#eeff00 50%, #ffb814 90%,transparent 100%);
	
	animation-name: stretch;
  animation-duration: 1.5s; 
  animation-timing-function: ease; 
  animation-delay: 0s;
/*   animation-direction: alternate; */
  animation-iteration-count: infinite;
  animation-fill-mode: none;
  animation-play-state: running;
}

@keyframes stretch {
  0% {
		right: 30px;
		opacity: 0;
  }
  100% {
		right: -160px;
  }
}




/** Background animation */
.area{
  // background: #4e54c8;  
  // background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);  
  width: 100%;
  // height:100vh;
}

.circles{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  // perspective: 500px;	
}

.circles li{
  position: absolute;
  display: block;
  list-style: none;
  width: 15px;
  height: 5px;
  animation: animate 10s linear infinite;
	transform: rotateY(0);
  opacity: 0;
  background-color: gold;
}


@keyframes animate {
  from {
    transform: translateX(-100px) translateY(-500px)  rotateX(0);
    opacity: 1;
  }

	to {
    transform:  translateX(0px) translateY(3000px) rotateX(10turn);
    opacity: 0;
  }
}



.conic {
  position: relative;
  overflow: hidden;
  padding: 5px;

  &::before {
      content: '';
      position: absolute;
      z-index: 80;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background: conic-gradient(transparent, orange,rgb(136, 136, 69), transparent 30%);
      animation: rotate 4s linear infinite;
  }
}

@keyframes rotate {
  100% {
      transform: rotate(1turn);
  }
}



/** Carousel */

.carousel {
  width: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  position: relative;
  width: 200px;
  height: 50px;
}

.carousel-item {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  text-align: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out  ,visibility 0s linear;
  position: absolute;
  visibility: collapse;
  left: 0;
  top: 0;
}


.carousel-item.active {
  opacity: 1;
  visibility: visible;

}
.carousel_content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color:white;
  
}

.video_modal_content {
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.video_model_iframe {
  width: auto;
  height: 50vh;
  max-height: 550px;
  max-width:100%;
  aspect-ratio: 16 / 9;

  @media screen and (max-width: 468px) {
    max-height: 200px;
    width: 100%;
    height: auto;
  }


  @media screen and (max-width: 991px) {
    max-height: 300px;
    width: 100%;
    height: auto;
  }
}


.vidoe_rank_1st_svg {
  width: 30px;
  height: 30px;

  @media screen and (min-width: 991px) {
    width: 30px;
    height: 30px;
  }
}

.vidoe_rank_1st_svg_text {
  color: "#DEA651";
  margin-bottom: 0;
  font-size: 25px;
  font-weight: 600;
  @media screen and (min-width:991px) {
    font-size: 2em;
  }
}



.vidoe_rank_1st_title, .vidoe_rank_1st_vote {
  font-size: 0.7em;
  color:white;
  @media screen and (min-width: 991px) {
    font-size: 1em;
  }
}
