@import '../../../../assets/scss/custom/variables';

.container {
  background-color: #F5FAFE;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 4rem;
}

.banner_background_container {
  background-size: cover;
  background-repeat: no-repeat;
}

.banner_container {
  // margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
}

.banner_content {
  padding: 2rem;
}

.banner_image {
  aspect-ratio: 16/9;
  width: 100%;
  object-fit: cover;
}

.course_slick {
  margin-top: 1rem;
  margin-right: -1rem;

  :global .slick-track {
    display: flex ;
    gap: 0.4rem ;
  }
}

.course_card {
  height: 300px;
  margin-right: 1rem;
  cursor: pointer;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
}

.card_body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;

  width: 100%;
  height: 100%;
  background-color: white;
  transition: transform 0.5s ease-in-out;
}

.course_badge {
  font-size: x-small;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: normal;
  text-align: start;
  text-transform: capitalize;
}

.course_title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  min-height: 2rem;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.course_banner_image {
  width: 100%;
  height: 50%;
  aspect-ratio: 16/9;
  object-fit: cover;
  transition:  opacity 0.5s ease-in-out, height 0.5s ease-in-out;
  opacity: 1;
}
.image_hovered {
  opacity: 0.3;
  height: 0%;
}

.skill_container {
  transition:  opacity 0.5s ease-in-out, height 0.5s ease-in-out;
  opacity: 0;
}
.skill_hovered {
  opacity: 1;
}

.learner_count {
  font-size: small;
}

.category_selections {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.category_option {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  background-color: #EEEEEE;
  padding: 0.25rem 2rem;
  font-size:small;

  &.selected {
    background-color: #3A83C4;
    color: white;
  }
}

.interested_course_placeholder {

}

.bundle_empty_title {
  font-size: $fs18;
}

.bundle_title {
  font-weight:700;
  color: #011932;
  font-size: $fs40;
}
.bundle_title_blue {
  color: $fca-blue;
}
.bundle_description {
  color: #011932;
  font-size: $fs18;
}
.slider_title {
  font-weight: 600;
  color: 'black';
  font-size: $fs26;
  margin-bottom: 0px;
}

// responsive
@media (max-width: 1318px) {
  .bundle_title {
      font-size: $fs36;
  }

  .container {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .slider_title {
    font-weight: 600;
    color: 'black';
    font-size: $fs24;
    margin-bottom: 0px;
  }
}

@media (max-width: 991px) {
  .bundle_title {
      font-size: $fs33;
  }
  .container {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .slider_title {
    font-weight: 600;
    color: 'black';
    font-size: $fs22;
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  .bundle_title {
      font-size: $fs30;
  }
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .banner_background_container {
    background-size: cover;
    background-repeat: no-repeat;
  }
  .slider_title {
    font-weight: 600;
    color: 'black';
    font-size: $fs20;
    margin-bottom: 0px;
  }
}

