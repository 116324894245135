@import "../../../../assets/scss/custom/variables";


.successContainer {
    line-height: $fs30;
    color: #aaaaaa;
    font-size: $fs14;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center !important;
}

.success_reschedule_container {
    position: relative;
    .vector_animate_ele {
      transition: all 0.5s ease;
      align-self: center;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 350px;
      width: 400px;
    }
    .vector_animate_wrap > * {
      position: absolute;
      width: 5vw;
    }
    .vector_animate_ele > * {
      position: absolute;
    }
    .banner_1_item_image {
      height: 100%;
      width: 100%;
    }
    .vector_animate_wrap >.item_1 {
      width: 70%;
    }
    .vector_animate_wrap >.item_2 {
        width: 64%;
        left: 19%;
        bottom: 14%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_3 {
        width: 5%;
        left: 44%;
        bottom: 50%;
        z-index: 30;
    }
    .vector_animate_wrap >.item_4 {
        width: 6%;
        left: 20%;
        bottom: 80%;
        z-index: 30;
    }
    .vector_animate_wrap >.item_5 {
        width: 3%;
        left: 15%;
        bottom: 74%;
        z-index: 30;
    }
    .vector_animate_wrap >.item_6 {
        width: 2%;
        left: 43%;
        bottom: 71%;
        z-index: 30;
    }
    .vector_animate_wrap >.item_7 {
        width: 4%;
        right: 14%;
        bottom: 55%;
        z-index: 30;
    }
  
    .scale_animation_1 {
      animation: scaleAnimation1 2s infinite alternate;
    }
    @keyframes scaleAnimation1 {
        from {
            transform: scale(1.0);
          }
        to {
            transform: scale(1.1);
        }
    }
    .scale_animation_2 {
      animation: scaleAnimation2 1s infinite alternate;
    }
    .scale_animation_3 {
      animation: scaleAnimation2 1.5s infinite alternate;
    }
     .scale_animation_4 {
      animation: scaleAnimation2 2s infinite alternate;
    }
    @keyframes scaleAnimation2 {
        from {
            transform: scale(1.0);
          }
        to {
            transform: scale(1.4);
        }
    }
  
    .moving_horizontal_1 {
      animation-name : horizontalmove1;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
    }
    @keyframes horizontalmove1 {
      from {transform: translateX(0px);}
      to {transform: translateX(20px);}
    }
}
  
.success_title_text {
    font-size: $fs30;
    color: $fca-dark;
    font-family: $font_2;
    margin-top: 0.5rem;
}
.success_desc_text {
    font-size: $fs14;
    color: $fca-dark;
    margin-top: 0.5rem;
    padding-left: 5rem;
    padding-right: 5rem;
}
  