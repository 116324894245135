@import url("https://fonts.googleapis.com/css?family=Raleway:400");
.root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #F5FAFE;
}

.stepper_container {
    background-color: white;
}

.main {
    flex-grow: 1;
    margin-top: 20px;
    padding-bottom: 200px;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: 992px) {
        padding-left: 100px;
        padding-right: 100px;
    }
}

.enquiry, .callme {
    background-color: white;
    padding: 20px;
    position: sticky;
    border-top: 5px solid #3A83C4;
}

.callme_blue {
    border-top: 5px solid #3A83C4;
} 

.callme_green {
    border-top: 5px solid #00AB84;
} 

.callme_yellow {
    border-top: 5px solid #FFBD43;
} 



.alert {
    background-color: #E7F2F9;
    color: #7C89E2;
}

.call_us_btn {
    width: 100%;
    border: 1px solid #4BA58C;
    color: #00AB84;
    background-color: #e5f6f2;
}

.glowing_circle {
    -webkit-animation: glowing 2s ease-in-out infinite alternate;
    -moz-animation: glowing 2s ease-in-out infinite alternate;
    animation: glowing 2s ease-in-out infinite alternate;
}

@keyframes glowing {
    0% {
        box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #4BA58C, 0 0 8px #4BA58C, 0 0 10px #4BA58C;
    }
    50% {
        box-shadow: 0 0 2px #3B85C4, 0 0 4px #4BA58C;
    }
    100% {
        box-shadow: 0 0 2px #fff, 0 0 4px #fff, 0 0 6px #3B85C4, 0 0 8px #3B85C4, 0 0 10px #3B85C4;
    }
}

.maintenance_page {
    background-color: #F5FAFE;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}