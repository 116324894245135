@import '../../../../assets/scss/custom/variables';




.referral_dashboard_container {
    padding-left: 6rem;
    padding-right: 6rem;
}

// banner
.referral_banner_container {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: rgb(228,243,250);
    padding: 2rem;
    overflow: hidden;
    margin-bottom: 2rem;
}
.referral_banner_image_container {
    display: flex;
    flex: 5;
    position: relative;
}
.referral_banner_text_container {
    display: flex;
    flex: 5;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    padding-left: 3rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.referral_banner_title {
    font-size: $fs30;
    color: $fca-dark;
    font-family: $font_2;
    line-height: 1.2;
}
.referral_banner_title_green {
    color: $fca-green;
}
.referral_banner_desc {
    font-size: $fs22;
    color: $fca-dark;
    line-height: 1.2;
}
.referral_banner_desc_green {
    color: $fca-green;
    font-weight: bold;
}
.refferal_link_button_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-top: 0.5rem;
}

// banner floating
.referral_banner_floating_container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .banner_main_image {
        height: auto;
        width: 100%;
        z-index: 10;
        object-fit: contain;
    }
    .vector_animate_ele {
        position: absolute;
        transition: all 0.5s ease;
    }
    .vector_animate_wrap > * {
      position: absolute;
      width: 5vw;
    }
    .vector_animate_ele > * {
      position: absolute;
    }
    .banner_1_item_image {
      height: 100%;
      width: 100%;
    }
    .vector_animate_wrap >.item_1 {
      width: 70%;
    }
    .vector_animate_wrap >.item_2 {
        width: 15%;
        left: -5%;
        bottom: 1%;
    }
    .vector_animate_wrap >.item_3 {
        width: 5%;
        right: 16%;
        bottom: 10.5%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_4 {
        width: 6%;
        left: 46%;
        bottom: 52%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_5 {
        width: 14%;
        right: 20%;
        bottom: 65%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_6 {
        width: 16%;
        right: 30%;
        bottom: 89%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_7 {
        width: 14%;
        left: -3%;
        bottom: 82%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_8 {
        width: 20%;
        left: -23%;
        bottom: 25%;
    }


  .cloud_animation1 {
    animation-name : cloudanimation1;
    animation-iteration-count : infinite;
    animation-direction: alternate;
    animation-duration : 10s;
  }
  @keyframes cloudanimation1 {
    0% {
        transform: translate(0px, 0px);
        opacity: 1;
    }
    25% {
        transform: translate(20px, 0px);
        opacity: 0;
    }
    50% {
        transform: translate(40px, 0px);
        opacity: 1;
    }
    75% {
      transform: translate(60px, 0px);
      opacity: 0;
    }
    100% {
        transform: translate(80px, 0px);
        opacity: 1;
    }
  }
  .cloud_animation2 {
    animation-name : cloudanimation2;
    animation-iteration-count : infinite;
    animation-direction: alternate-reverse;
    animation-duration : 10s;
  }
  @keyframes cloudanimation2 {
    0% {
        transform: translate(0px, 0px);
        opacity: 0;
    }
    25% {
        transform: translate(20px, 0px);
        opacity: 1;
    }
    50% {
        transform: translate(40px, 0px);
        opacity: 0;
    }
    75% {
      transform: translate(60px, 0px);
      opacity: 1;
    }
    100% {
        transform: translate(80px, 0px);
        opacity: 0;
    }
  }
  .cloud_animation3 {
    animation-name : cloudanimation3;
    animation-iteration-count : infinite;
    animation-direction: alternate;
    animation-duration : 10s;
  }
  @keyframes cloudanimation3 {
    0% {
        transform: translate(0px, 0px);
        opacity: 0;
    }
    25% {
        transform: translate(20px, 0px);
        opacity: 1;
    }
    50% {
        transform: translate(40px, 0px);
        opacity: 0;
    }
    75% {
      transform: translate(60px, 0px);
      opacity: 1;
    }
    100% {
        transform: translate(80px, 0px);
        opacity: 0;
    }
  }
  .cloud_animation4 {
    animation-name : cloudanimation4;
    animation-iteration-count : infinite;
    animation-direction: alternate;
    animation-duration : 10s;
  }
  @keyframes cloudanimation4 {
    0% {
        transform: translate(0px, 0px);
        opacity: 0;
    }
    25% {
        transform: translate(20px, 0px);
        opacity: 1;
    }
    50% {
        transform: translate(40px, 0px);
        opacity: 0;
    }
    75% {
      transform: translate(60px, 0px);
      opacity: 1;
    }
    100% {
        transform: translate(80px, 0px);
        opacity: 0;
    }
  }

  .gift_animation {
    animation-name : giftanimation;
    animation-iteration-count : infinite;
    animation-direction: normal;
    animation-duration : 5s;
  }
  @keyframes giftanimation {
    0% {
        transform: rotate(0deg);
    }
    4% {
        transform: rotate(10deg);
    }
    6% {
        transform: rotate(-10deg);
    }
    8% {
        transform: rotate(10deg);
    }
    10% {
        transform: rotate(-10deg);
    }
    12% {
        transform: rotate(10deg);
    }
    14% {
        transform: rotate(-10deg);
    }
    16% {
        transform: rotate(10deg);
    }
    18% {
        transform: rotate(-10deg);
    }
    20% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
  }

  .leaf_animation {
    animation-name : leafanimation;
    animation-iteration-count : infinite;
    animation-direction: alternate;
    animation-duration : 5s;
  }
  @keyframes leafanimation {
    0% {
        transform: rotate(-15deg);
    }
    100% {
        transform: rotate(10deg);
    }
  }

  .coin_animation {
    animation-name : coinanimation;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 1s;
  }
  @keyframes coinanimation {
    from {transform: translateY(0px);}
    to {transform: translateY(-10px);}
  }
}

.outline_green_button{
    cursor: pointer;
    font-weight: bold;
    padding:  $pd15;
    font-size: $fs15;
    line-height: 1.2;
    border-radius: 0;
    min-width: $pd160;

    svg{
        width: $fs15;
        height: $fs15;
        margin-right: 5px;
    }
    display: inline-flex;
    border: 1px solid $fca-green;
    color: $fca-green;
    background-color: $fca-green-light;
    align-items: center;
    justify-content: center;
    min-width: $pd300;
    text-align: center;
    path{
        fill: $fca-green;
    }
    i:last-child{
        font-size: $fs22;
        margin-left: auto;
    }
    &.cert-icon{
      path{
        fill: #fff;
      }
      path:first-child{
        fill: $fca-green;
      }
      circle{
        fill: $fca-green;            
      }
    }
}

// databox
.referral_databox_container {
    .referral_databox_title_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .referral_databox_title {
        font-size: $fs40;
        color: $fca-dark;
        font-family: $font_2;
        line-height: 1.2;
        margin-bottom: 0;
    }
   
    .databox_row {
        display: flex;
        margin-right: -10px;
        margin-left: -10px;
        margin-top: 1.5rem;
    }
    .databox_col {
        display: flex;
        padding: 0 10px;
        margin-bottom: 20px;
    }
    .referral_databox_referral_container{
        flex: 1;
        background-color: #E5F3FA;
        border-top: 2px solid $fca-blue;
        padding: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .databox_referral_number {
            font-size: $fs40;
            font-family: $font_2;
            color: $fca-blue;
            margin-bottom: 0.5rem;
        }
        .databox_desc {
            font-size: $fs15;
            color: $fca-dark;
        }
        svg {
            width: $fs60;
            height: $fs60;
        }
    }
    .referral_databox_voucher_container {
        flex: 1;
        background-color: #E5F7F2;
        border-top: 2px solid $fca-green;
        padding: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .databox_voucher_number {
            font-size: $fs40;
            font-family: $font_2;
            color: $fca-green;
            margin-bottom: 0.5rem;
        }
        .databox_desc {
            font-size: $fs15;
            color: $fca-dark;
        }
        svg {
            width: $fs60;
            height: $fs60;
        }
    }
    .referral_databox_redeemed_container {
        flex: 1;
        background-color: #FFF8EC;
        border-top: 2px solid $fca-yellow;
        padding: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .databox_redeemed_number {
            font-size: $fs40;
            font-family: $font_2;
            color: $fca-yellow;
            margin-bottom: 0.5rem;
        }
        .databox_desc {
            font-size: $fs15;
            color: $fca-dark;
        }
        svg {
            width: $fs60;
            height: $fs60;
        }
    }
}

// datatable
.referral_datatable_container {
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 2rem;
    .referral_datatable_title_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 2px solid $fca-blue;
    }
    .referral_datatable_title {
        font-size: $fs22;
        color: $fca-dark;
        font-family: $font_2;
        line-height: 1.2;
        margin-bottom: 0;
    }
    .referral_datatable_table_container {
        padding: 1rem;
    }
    .referral_datatable_table {
        border: 1px solid #DDDDDD;
        .datatable_table_header {
            background-color: $fca-blue;
        }
        .datatable_header_th {
            text-transform: none;
            font-family: $font_1;
            color: #ffffff;
            font-size: $fs14;
            font-weight: bold;
            padding: 1rem 2rem;
        }
        .datatable_table_body {
            background-color: #f8f8f8;
            td {
                padding: 1rem 2rem;
                font-size: $fs14;
                color: $fca-dark;
            }
        }
        .table_status_container {
            display: inline-block;
            background-color: #E5F7F2;
            border-radius: 5px;
            padding: 3px 1rem;
        }
        .table_status_container_orange {
            display: inline-block;
            background-color: $fca-yellow-light;
            border-radius: 5px;
            padding: 3px 1rem;
        }
        .table_status_container_red {
            display: inline-block;
            background-color: $fca-red-light;
            border-radius: 5px;
            padding: 3px 1rem;
        }
        .table_status_container_grey {
            display: inline-block;
            background-color: #DDDDDD;
            border-radius: 5px;
            padding: 3px 1rem;
        }
        .table_status_text {
            font-size: $fs12;
            color: $fca-green;
        }
        .table_status_text_orange {
            font-size: $fs12;
            color: $fca-yellow;
        }
        .table_status_text_red {
            font-size: $fs12;
            color: $fca-red;
        }
        .table_status_text_grey {
            font-size: $fs12;
            color: $fca-dark;
        }
        .table_redeem_container {

        }
        .table_redeem_button {
            display: block;
            box-shadow: none;
        }
        .table_redeem_button_text {

        }
    }
    .alumni_status {
        border: 1px solid #DDDDDD;
    }
}





// Responsive
@media (max-width: 1199px) {
    .referral_dashboard_container {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    
}

@media (max-width: 980px) {
    .referral_dashboard_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .referral_banner_text_container {
        padding-left: 2rem;
    }
    .refferal_link_button_container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0.5rem;
    }
}

@media (max-width: 767px) {
    .referral_dashboard_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}


@media (max-width: 575px) {
    .referral_dashboard_container {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .referral_banner_container {
        flex-direction: column;
    }
    .referral_banner_floating_container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
    .referral_banner_text_container {
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}