.categoryList {
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
}

.categoryChip {
	padding: 10px 5px;
	border-radius: 20px;
	cursor: pointer;

	&:global(.MuiChip-root) {
		background-color: white;
	}
}

.search_form {
	padding-top: 50px;
	@media screen and (max-width: 768px) {
		padding-top: 20px;
	}
}

.search_category_filter {
	margin-top: 30px;
	@media screen and (max-width: 768px) {
		margin-top: 20px;
	}
}

.searchInput {
	width: 100%;
	margin: 0 auto;
	max-width: min(700px, 90vw);
	margin-bottom: 20px;
	height: 50px;

	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: center;

	border-radius: 40px;
	border: 1px solid #dddddd;
	background-color: white;
	opacity: 1;

	.input {
		display: flex;
		flex: 1;
		flex-grow: 1;
		flex-shrink: 1;
		border-width: 0;
		background-color: transparent;
		padding: 15px 40px;
		font-size: 1.3rem;

		@media screen and (max-width: 768px) {
			font-size: 1rem;
			padding: 10px 15px;
		}
	}

	.input::placeholder {
		color: #aaaaaa;
		font-size: 1rem;
		font-weight: 300;
	}

	.searchButton {
		align-items: center;
		justify-content: center;
		height: 85%;
		border-radius: 50%;
		margin-bottom: 0;
		margin-right: 3px;
		// border-radius: 55px;
		font-size: 1rem;
		aspect-ratio: 1;
		border-color: transparent;
		background-color: #bbbbbb;
		color: white;
	}
}

.featured_event_card {
	background-color: white;
	border-radius: 20px;
	display: flex;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	}

	.featured_event_info {
		flex: 1;
		flex-grow: 1;
		flex-shrink: 1;
		padding: 30px 30px;
		display: flex;
		flex-direction: column;

		.title {
			font-size: 1.3rem;
			font-weight: 700;
			margin-bottom: 10px;
		}

		.description {
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 3; /* number of lines to show */
			line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}

	.featured_event_img {
		flex: 1;
		flex-grow: 1;
		flex-shrink: 1;
		background-color: #dddddd;
		border-radius: 20px;
		overflow: hidden;
		background-color: black;

		img {
			border-radius: 20px;
			min-height: 320px;
			width: 100%;
			height: 100%;
			object-fit: contain;

			@media screen and (max-width: 768px) {
				min-height: 240px;
			}
		}
	}
}

.list_container {
	@media screen and (max-width: 768px) {
		max-width: 90%;
	}

	@media screen and (max-width: 992px) {
		max-width: 800px;
	}

	@media screen and (max-width: 1200px) {
		max-width: 1100px;
	}

	max-width: 1330px;
}

.event_hero_section {
	position: relative;
	--event-hero-height: 800px;
	height: auto;

	@media screen and (max-width: 768px) {
		// --event-hero-height: 1000px;
	}

	// @max-width (768px) {
	// 	--event-hero-height: 1000px;
	// }

	.event_hero_img {
		background-image: url("/assets/img/Events/earth-transparent.png");
		min-height: fit-content;
		// height: calc(var(--event-hero-height) + 200px);
		// opacity: 0.05;
		// background-position: top  ;
		background-position: top;
		background-size: cover;
		background-repeat: no-repeat;
		padding-bottom: 50px;
	}
}

.event_past_event_section {
	background: linear-gradient(180deg, #edf8f5 0%, #edf8f5 30%, rgba(255, 255, 255, 0) 100%);
	background-repeat: no-repeat;
	padding: 25px 0;
	position: relative;
}

.event_past_event_section_container {
	width: 100%;
	padding-top: 50px;
}

.event_past_event_section_background {
	background: url(./bg_dots01b@2x-min.png);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: top 0 left 0;
	width: 100%;
	// opacity: 0.1;
	// height: 400px;
	top: 50px;
	z-index: 0;
}

.event_past_event_blur {
	padding: 100px 0;
}

.event_small_card_list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	column-gap: 25px;
}

.badge_black {
	background-color: black;
	color: white;
	text-transform: capitalize;
}

.event_small_card {
	position: relative;
	margin-top: 50px;
	background-color: white;
	border-radius: 10px;
	overflow: hidden;
	flex-grow: 1;
	min-height: 300px;

	.event_ended_badge {
		position: absolute;
		top: 10px;
		right: 10px;
		text-transform: capitalize;
		font-size: 0.8rem;
		background-color: black;
		color: white;
		text-transform: capitalize;
	}

	img {
		border-radius: 5px;
		// height: 150px;
		width: 100%;
		aspect-ratio: 16/9;
		border-width: 0;
		object-fit: cover;
	}

	.event_title {
		font-size: 0.9rem;
	}

	.event_category {
		font-size: 0.7rem;
		border-radius: 5px;
		text-transform: none;
	}
}

.slider {
	&:global(.slick-slider) {
		-webkit-user-select: text;
		-khtml-user-select: text;
		-moz-user-select: text;
		-ms-user-select: text;
		user-select: text;
	}
}

.search_checkbox {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;

	:global(.MuiTypography-body1) {
		font-family: "Poppins", sans-serif !important;
		font-weight: 300;
	}

	:global(.MuiSvgIcon-root) {
		stroke: #efefef;
	}
}
