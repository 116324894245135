html,body,#root,.main-content{
    height: 100%;
    // min-height: 100vh;
}

.table-responsive::-webkit-scrollbar {
  -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
  width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
  height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;  
  background-color: #ffffff; 
}

.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.pagination .previous a {
  color: #8898aa;
}
.pagination .next a {
  color: #8898aa;
}


.pagination a {
  cursor: pointer;
  margin: 0 5px;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.pagination .active a {
  background-color: $fca-green;
  color: #fff;
  border: 1px solid $fca-green-light;
}

.main-content-container{
    height: auto;
    min-height: 70vw;
}

body {
    color: $body-color;
    font-family: $font_1;
    font-size: $fs16;
    font-weight: normal;
    line-height: 1.4;
    background: $white;
}
a{
  color: $fca-blue;
}
p{
  font-size: $fs16;
    line-height: 1.4;
}
p:last-child{
    margin-bottom: 0;
}
b{
  font-weight: 600;
}

h1 {
    color: $yellow;
    font-family: $font_4;
    font-size: dynamic_px(35, 50);
    font-weight: normal;
    line-height: 1.2;
}

h2{
    color: $body-color;
    font-family: $font_2;
    font-size: $fs36;
    font-weight: normal;
    line-height: 1.2;

}

h3{
    color: $title-color;
    font-family: $font_1;
    font-size: $fs33;
    font-weight: bold;
    line-height: 1.2;
}

h4{
    color: $body-color;
    font-family: $font_2;
    font-size: $fs24;
    font-weight: normal;
    line-height: 1.2;
}

h5{
    color: $body-color;
    font-family: $font_2;
    font-size: $fs26;
    line-height: 1.2;
    font-weight: bold;
}

ul{
  padding-left: $pd30;
}

.card{
    border-radius: 0;
    box-shadow: $shadow;
}

.fs12{
  font-size: $fs12;
}

.fs14{
  font-size: $fs14;
}

.btn {
    font-weight: bold;
    padding:  $pd15;
    font-size: $fs15;
    line-height: 1.2;
    border-radius: 0;
    min-width: $pd160;

    svg{
      width: $fs15;
      height: $fs15;
    }

    &.btn-gradient{
      padding:  $pd15 $pd45;
        background-color: rgb(81,168,134) !important;
        background-image: linear-gradient(90deg, $fca-green 0%, $fca-blue 50%) !important;
        background-position: left center;
        background-size: 150% 100%;
        color: #ffffff !important;
        font-weight: 600;
        border: 0;
        transition: all .3s ease;
        &:hover{
            background-position: right center;
        }
    }
    &.btn-outline-black{
        display: inline-flex;
        border: 1px solid $gray-ccc;
        align-items: center;
        i:last-child{
            font-size: $fs22;
            margin-left: auto;
        }
    }
    &.btn-outline-green{
        display: inline-flex;
        border: 1px solid $fca-green;
        color: $fca-green;
        background-color: $fca-green-light;
        align-items: center;
        justify-content: center;
        min-width: $pd200;
        text-align: center;
        path{
            fill: $fca-green;
        }
        i:last-child{
            font-size: $fs22;
            margin-left: auto;
        }
        &.cert-icon{
          path{
            fill: #fff;
          }
          path:first-child{
            fill: $fca-green;
          }
          circle{
            fill: $fca-green;            
          }
        }
    }
    &.btn-outline-green2{
      display: inline-flex;
      border: 1px solid $fca-green;
      color: $fca-green;
      background-color: $white;
      align-items: center;
      justify-content: center;
      min-width: $pd200;
      path{
          fill: $fca-green;
      }
      i:last-child{
          font-size: $fs22;
          margin-left: auto;
      }
      &.cert-icon{
        path{
          fill: #fff;
        }
        path:first-child{
          fill: $fca-green;
        }
        circle{
          fill: $fca-green;            
        }
      }
  }
    &.btn-link-green{
        display: inline-flex;
        border: 0;
        background: transparent;
        color: $fca-green;
        align-items: center;
        path{
            fill: $fca-green;
        }
    }
    &.btn-default{
        padding:  $pd15 $pd45;
        background-color: #EEEEEE;
        box-shadow: none;
    }
    &.btn-disable{
        // background-color: $gray-ccc;
        // color: $gray-666;
        // border: 0;
        // &:hover{
        //     background-color: $gray-ccc;
        //     color: $gray-666;
        // }
    }
}

.svg-fill-blue {
  path{
    fill: $fca-blue;
  }
}

.course_detail label,
.course_detail p,
.course-session{
    font-size:$fs15;
}

h4{
    font-size: $fs22;
}
.text_purple{
    color:#8C4799;
}
/*.text_white{
    color:#fff;
}*/
.input-group-merge {
	.form-control {
        &.input-group-text{
            height:auto;
        }
		&:not(:first-child) {
		    padding-left:  0.75rem;
		}
        &:not(.is-invalid):focus{
            border-color: #eeeeee;
        }
	}
}

.form-group{
    .needs-validation{
        .input-group-text, .form-control{
            border-color: $red;
        }
    }
}
.form-control{
    font-size: $fs16;
    color: $body-color;
    &:focus{
        color: $body-color;
    }
}

.input-group-text,.form-control{
    border-radius: 0;
}

.invalid-feedback{
    text-transform: capitalize;
}

.modal-backdrop.show{
    opacity: 0.8;
  }
  
.modal {

}

.modal-header-wrap{
    width: 100%;
}
.slick-next:before, .slick-prev:before{
    color:black;
}

// need to clean up error fallback page
// .main-error-page {
//     min-height: 600px;
//     margin: 0px auto;
//     width: auto;
//     // max-width: 560px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

//     .error-title {
//       max-width: 529px; 
//       font-family: Roboto;
//       font-size: 38px;
//       font-weight: bold;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: normal;
//       letter-spacing: normal;
//       text-align: center;
//       color: #4b4b62;
//       margin-bottom: 16px;
//     }
    
//     .error-subtitle {
//       max-width: 568px; 
//       font-family: Roboto;
//       font-size: 16px;
//       font-weight: normal;
//       font-stretch: normal;
//       font-style: normal;
//       line-height: 1.31;
//       letter-spacing: normal;
//       text-align: center;
//       color: #4b4b62;
//       margin-bottom: 24px;
//     }
//     svg {
//       margin-bottom: 16px;
//     }
    
    
//     .full-torradeira {
       
//     }
//     .torradeira {
//     }
    
//     .pao-atras {
//       animation: leftright 1s alternate infinite;
//       transform-origin: center;
//     }
//     .pao-frente {
//         animation: leftright 1s 0.3s alternate infinite;
//       transform-origin: center;
//     }
    
//     .olho-esq {
      
//         animation: sad 2s alternate infinite;
//       transform-origin: center;
//     }
    
//     .olho-dir {
      
//         animation: sad 2s alternate infinite;
//       transform-origin: center;
//     }
//     .boca {
//         animation: sad 2s alternate infinite;
//       transform-origin: center;
//     }
//     .raios {
//       -webkit-animation: flicker-4 4s linear infinite both;
//       animation: flicker-4 4s linear infinite both;
//     }
//     .tomada {
//       -webkit-animation: vibrate-1 3s linear infinite both;
//                 animation: vibrate-1 3s linear infinite both;
//     }
//     .fio-500 {
//       -webkit-animation: vibrate-1 3s linear infinite both;
//                 animation: vibrate-1 3s linear infinite both;
//     }
//     .fio{ 
//       -webkit-animation: vibrate-1 3s linear infinite both;
//                 animation: vibrate-1 3s linear infinite both;
//     }
    
//     @keyframes scales {
//       from {
//         transform: scale(0.98);
//       }
//       to {
//         transform: scale(1);
//       }
//     }
    
//     /* ----------------------------------------------
//      * Generated by Animista on 2020-4-1 14:58:16
//      * Licensed under FreeBSD License.
//      * See http://animista.net/license for more info. 
//      * w: http://animista.net, t: @cssanimista
//      * ---------------------------------------------- */
    
//     /**
//      * ----------------------------------------
//      * animation flicker-4
//      * ----------------------------------------
//      */
//     @-webkit-keyframes flicker-4 {
//       0%,
//       100% {
//         opacity: 1;
//       }
//       31.98% {
//         opacity: 1;
//       }
//       32% {
//         opacity: 0;
//       }
//       32.8% {
//         opacity: 0;
//       }
//       32.82% {
//         opacity: 1;
//       }
//       34.98% {
//         opacity: 1;
//       }
//       35% {
//         opacity: 0;
//       }
//       35.7% {
//         opacity: 0;
//       }
//       35.72% {
//         opacity: 1;
//       }
//       36.98% {
//         opacity: 1;
//       }
//       37% {
//         opacity: 0;
//       }
//       37.6% {
//         opacity: 0;
//       }
//       37.62% {
//         opacity: 1;
//       }
//       67.98% {
//         opacity: 1;
//       }
//       68% {
//         opacity: 0;
//       }
//       68.4% {
//         opacity: 0;
//       }
//       68.42% {
//         opacity: 1;
//       }
//       95.98% {
//         opacity: 1;
//       }
//       96% {
//         opacity: 0;
//       }
//       96.7% {
//         opacity: 0;
//       }
//       96.72% {
//         opacity: 1;
//       }
//       98.98% {
//         opacity: 1;
//       }
//       99% {
//         opacity: 0;
//       }
//       99.6% {
//         opacity: 0;
//       }
//       99.62% {
//         opacity: 1;
//       }
//     }
//     @keyframes flicker-4 {
//       0%,
//       100% {
//         opacity: 1;
//       }
//       31.98% {
//         opacity: 1;
//       }
//       32% {
//         opacity: 0;
//       }
//       32.8% {
//         opacity: 0;
//       }
//       32.82% {
//         opacity: 1;
//       }
//       34.98% {
//         opacity: 1;
//       }
//       35% {
//         opacity: 0;
//       }
//       35.7% {
//         opacity: 0;
//       }
//       35.72% {
//         opacity: 1;
//       }
//       36.98% {
//         opacity: 1;
//       }
//       37% {
//         opacity: 0;
//       }
//       37.6% {
//         opacity: 0;
//       }
//       37.62% {
//         opacity: 1;
//       }
//       67.98% {
//         opacity: 1;
//       }
//       68% {
//         opacity: 0;
//       }
//       68.4% {
//         opacity: 0;
//       }
//       68.42% {
//         opacity: 1;
//       }
//       95.98% {
//         opacity: 1;
//       }
//       96% {
//         opacity: 0;
//       }
//       96.7% {
//         opacity: 0;
//       }
//       96.72% {
//         opacity: 1;
//       }
//       98.98% {
//         opacity: 1;
//       }
//       99% {
//         opacity: 0;
//       }
//       99.6% {
//         opacity: 0;
//       }
//       99.62% {
//         opacity: 1;
//       }
//     }
    
    
//     /* ----------------------------------------------
//      * Generated by Animista on 2020-4-1 15:17:57
//      * Licensed under FreeBSD License.
//      * See http://animista.net/license for more info. 
//      * w: http://animista.net, t: @cssanimista
//      * ---------------------------------------------- */
    
//     /**
//      * ----------------------------------------
//      * animation vibrate-1
//      * ----------------------------------------
//      */
//     @-webkit-keyframes vibrate-1 {
//       0% {
//         -webkit-transform: translate(0);
//                 transform: translate(0);
//       }
//       20% {
//         -webkit-transform: translate(-2px, 2px);
//                 transform: translate(-2px, 2px);
//       }
//       40% {
//         -webkit-transform: translate(-2px, -2px);
//                 transform: translate(-2px, -2px);
//       }
//       60% {
//         -webkit-transform: translate(2px, 2px);
//                 transform: translate(2px, 2px);
//       }
//       80% {
//         -webkit-transform: translate(2px, -2px);
//                 transform: translate(2px, -2px);
//       }
//       100% {
//         -webkit-transform: translate(0);
//                 transform: translate(0);
//       }
//     }
//     @keyframes vibrate-1 {
//       0% {
//         -webkit-transform: translate(0);
//                 transform: translate(0);
//       }
//       20% {
//         -webkit-transform: translate(-2px, 2px);
//                 transform: translate(-2px, 2px);
//       }
//       40% {
//         -webkit-transform: translate(-2px, -2px);
//                 transform: translate(-2px, -2px);
//       }
//       60% {
//         -webkit-transform: translate(2px, 2px);
//                 transform: translate(2px, 2px);
//       }
//       80% {
//         -webkit-transform: translate(2px, -2px);
//                 transform: translate(2px, -2px);
//       }
//       100% {
//         -webkit-transform: translate(0);
//                 transform: translate(0);
//       }
//     }
    
    
    
//     /* ----------------------------------------------
//      * Generated by Animista on 2020-4-1 15:42:45
//      * Licensed under FreeBSD License.
//      * See http://animista.net/license for more info. 
//      * w: http://animista.net, t: @cssanimista
//      * ---------------------------------------------- */
    
//     /**
//      * ----------------------------------------
//      * animation wobble-ver-right
//      * ----------------------------------------
//      */
//     @-webkit-keyframes wobble-ver-right {
//       0%,
//       100% {
//         -webkit-transform: translateY(0) rotate(0);
//                 transform: translateY(0) rotate(0);
//         -webkit-transform-origin: 50% 50%;
//                 transform-origin: 50% 50%;
//       }
//       15% {
//         -webkit-transform: translateY(-30px) rotate(6deg);
//                 transform: translateY(-30px) rotate(6deg);
//       }
//       30% {
//         -webkit-transform: translateY(15px) rotate(-6deg);
//                 transform: translateY(15px) rotate(-6deg);
//       }
//       45% {
//         -webkit-transform: translateY(-15px) rotate(3.6deg);
//                 transform: translateY(-15px) rotate(3.6deg);
//       }
//       60% {
//         -webkit-transform: translateY(9px) rotate(-2.4deg);
//                 transform: translateY(9px) rotate(-2.4deg);
//       }
//       75% {
//         -webkit-transform: translateY(-6px) rotate(1.2deg);
//                 transform: translateY(-6px) rotate(1.2deg);
//       }
//     }
     
    
//     @keyframes sad {
//       0% {
//         transform: rotateX(0deg) rotateY(0deg);
//       }
//       100% {
//         transform: rotateX(10deg)  rotateY(5deg);
//       }
//     }
    
//     @keyframes leftright{
      
//       0%{
//         transform: rotateZ( 0deg)
//       }
//        100%{
//         transform: rotateZ( -15deg)
//       }
//     }
//   }

// End need to clean up error fallback page





/* casper */

/* common style */
.w-60px{
    width: $fs60;
}
.w-40px{
    width:40px;
}
.minh-40px{
    min-height:40px;
}
.w-20px{
    width: $fs20;
}

.h-20px{
    height: 20px;
}
.h-30px{
  // height: $fs40;
  height: 30px;
}
.h-40px{
    // height: $fs40;
    height: 40px;
}
.mw-580px{
  max-width: 580px;
}

.display_ini{
    display:initial!important;
}
.cursor-pointer{
    cursor:pointer;
}
.theme_text_color{
    color:rgb(55,132,196);
}
.bg-light-blue{
    background-color: rgba(205, 228, 243);
}
.bg-profile-light-blue{
  background-color: #0085CA45;
}
/* Dashboard */
.slick-next:before, .slick-prev:before{
    color:black;
}
.course_detail_modal, .registration_modal{
    max-width: 1000px;
}
.registration_modal{
    label{
        margin:10px 0;
    }
}
.trainer_img{
    background-color: #00ab84;
    border-radius: 50%;
}

.card-calendar .calendar .fc-toolbar {
    height:auto;
    padding:10px;
}
.card-calendar .fc .fc-toolbar.fc-header-toolbar{
    margin-bottom: 0;
}
.card-calendar .fc .fc-button-primary{
    background-color: rgb(55,132,196) !important;
}
.register_title{    
    margin-top:1.5rem;
    border-top: 1px solid #e0e0e0;
    padding-top: 10px;
}



// jenwei
.side-profile-header{
    background-color: rgba(205, 228, 243);
    h5{
    }
    .list-group-item{
        background-color: inherit;
    }
  }

.profile-list{
    label{
        margin-bottom: 0;
        font-size: $fs18;
        }

    .row{
        padding: $pd15 0;
        margin:  0;
    }
    
    .col{
        padding: 0;
    }

    .row + .row{
        border-top: 1px solid #f4f8fc;
    }
}

.profile-wrap{
  .profile-details{
    padding: 0 $pd30;
  }
  .profile-details + .profile-details{
    border-left: 1px solid #f4f8fc;
  }
}

.cta-wrap{
    svg{
        width: $fs30;
        height: $fs30;
        display: block;
        margin: auto;
    }
    span {
      font-size: $fs18;
      color: $fca-dark;
      font-weight: bold;
    }
    .card{
      margin-bottom: 0;
      height: 100%;
    }
    .card-body{
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      >*{        
        padding: $pd20 $pd15;
      }
    }
}


.sidenav {
    padding: $pd40 !important;
    background: transparent !important;
    border: none;
    box-shadow: none;
    .scrollbar-container{
        box-shadow: $shadow;
        background-color: $fca-blue;
        .sidenav-header{
            background-color: #fff;
        }
    }
}

.nav-active-bar {
  display: none;
}

.sidenav-header {
    height: auto;
}
.navbar-vertical .navbar-brand-img, .navbar-vertical .navbar-brand > img{
    width: 100%;
    max-height: unset;
    max-width: unset;
    
}

.navbar-vertical .navbar-nav .nav-item{
    padding: $pd15 0;
    .nav-link{
        font-size: $fs18;
        color: $gray-204;
        font-weight: 300;
        > i{
            font-size: $fs25;
            padding-right: $pd10;
        }
    }

    &.active{
        a,i{
            color: $fca-blue;
        }
    }
}

.scrollbar-inner {
  background-color: $white;
  box-shadow: $shadow;
}

.navbar-nav {
  flex-direction: row;
  background-color: $white;
}

.navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active{
    background: transparent;
}

.payment-method-row{
    img,svg{
        height: $fs30;
        width: auto;
    }
}

.info-wrap{
    margin-bottom: $pd15;
}

.courses-slider .slick-track{
    display: flex;
    padding: $pd20 0;
    padding-top: $pd10;
    width: 100%;
    .slick-slide {
        height: auto;
        >div{
            height: 100%;
            >div{
                >a{
                    height: 100%;
                    .card-body{
                        height: 100%;
                    }
                }
            }
        }
        img{
            display: inline-block;
        }
        img:not(.trainer-img,.h-30px, .h-20px){
            width: $fs15;
        }
        .trainer-img{
            width: $fs60;
            height: $fs60;
            border-radius: 50%;
            aspect-ratio: 1/1;
            object-fit: cover;
            object-position: center;
        }
    }
}

.adaptive-slider .slick-track{
  display: flex;
  padding: $pd20 0;
  padding-top: $pd10;
  .slick-slide {
      height: auto;
      >div{
          height: 100%;
      }
  }
}
.adaptive-slider {
  .slick-list{
    transition: height 0.5s ease-in-out; /* Add a height transition */
  }
} 
.adaptive-mobile-slider .slick-track{
  display: flex;
  padding: $pd20 0;
  padding-top: $pd10;
  .slick-slide {
      >div{
      }
  }
}
.adaptive-mobile-slider {
  .slick-list{
    transition: height 0.5s ease-in-out; /* Add a height transition */
  }
} 

.trainer-img{
    width: $fs60;
    height: $fs60;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
    background-color: rgb(238,238,238);
}

.available-course-card{
    .card{
        height: 100%;
    }
}

.bundle-course-card{
  
}

.bundle-course-description{
  

}
.bundle-course-title{
  .icon-completed-container{
    margin-top: 5px;
    width: $fs16;
    height: $fs16;
    border-radius: 50%;
    position: relative;
    flex: unset;
    max-width: unset;
    img{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100% !important;
      aspect-ratio: 1/1;
      object-fit: cover;
      object-position: center;
    }
  }
 

  .title-container{
    flex: unset;
    max-width: unset;
    width: calc(100% - (20px + (30 - 20) * (100vw - 375px) / (1900 - 375)));
    line-height: $fs30;
    color: #aaaaaa;
    font-size: $fs14;
  }

  &.registered{
    .title-container{
      color: $fca-dark;
    }
  }
}

.icon-container{
  margin-top: 5px;
  width: $fs16;
  height: $fs16;
  border: 1px solid $gray-ccc;
  border-radius: 50%;
  position: relative;
  flex: unset;
  max-width: unset;
  img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }
}
.icon-selected-container{
  margin-top: 5px;
  width: $fs16;
  height: $fs16;
  border: 1px solid $fca-green;
  border-radius: 50%;
  position: relative;
  flex: unset;
  max-width: unset;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-circle {
  width: $fs16;
  height: $fs16;
  border-radius: 50%;
  background-color: $fca-green;
}

.cert_date_completed{
  align-items: center;
  .icon-container{
    width: $fs20;
    height: $fs20;
  }
}

.limit-line-3{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
 }

 .dropzone {
    border: 2px dashed #ddd;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
  }
  
  .dropzone.active {
    background-color: #f7f7f7;
  }

  .tag{
    line-height: 1;
    padding: $pd5 $pd15;
    font-size: $fs14;
  }

  .small-tag{
    line-height: 1;
    padding: $pd2 $pd8;
    font-size: $fs12;
    word-wrap: break-word;
  }

  .signup_wrap{
    font-weight: bold;
  }

  .arrow-wrap{
    .arrow{
        width: $fs30;
        height: $fs30;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        box-shadow: $shadow;
        cursor: pointer;
        i{
            font-size: $fs22;

        }
        &.disable{
            opacity: 0.5;
            cursor: default;
            pointer-events: none;
        }
        &.arrow-left{

        }
        &.arrow-right{

        }
    }
  }
  
  .logout-color{
    color: #9CA3AC;
    path{        
        fill: #9CA3AC;
    }
  }

  .form-control-label {
    color: $body-color;
    font-size: $fs18;
    font-weight: bold;
}

.course-sticker{
    &.pending-reschedule{
        background-color: $fca-yellow-light;
        color: $fca-yellow;
    }
    &.pending-withdrawal{
        background-color: $fca-purple-light;
        color: $fca-purple;
    }
    &.rejected{
        background-color: $fca-red-light;
        color: $fca-red;
    }
    &.pending-assessment{
        background-color: $fca-blue-light;
        color: $fca-blue;
    }
    &.green-sticker{
        background-color: $fca-green-light;
        color: $fca-green;
    }
}

.event_register_modal {
  .modal-content{
    background-color: #F7F7F7;
  }
}

.profile_qr_modal {
  .modal-content{
    background-color: #E8F7FF;
  }
}

.cert_modal{
  width: 100%;
  height: 100%;
  max-width: unset;
  text-align: center;
  margin: 0;
  .modal-content{
    background-color: #1F2A44;
    width: 100%;
    height: 100%;
    .close-btn{
      position: absolute;
      top: $pd40;
      right: $pd40;
      font-size: $pd40;
      color: #fff;
      cursor: pointer;
      z-index: 99;
    }
  }
  h4{
    color: #fff;
  }
  .pdf-container{
    canvas{
      height: 70vh !important;
      width: auto !important;
      margin: auto;
    }
  }
  .textLayer, .annotationLayer{
    display: none;
  }

}

.cert_modal.shuttlebus-modal{
  .modal-content{
    .modal-body{
      max-width: 1200px;
      width: 90%;
      margin: auto;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        display: block;
        width: 100%;
      }
    }
  }
}

.flex-unset{
  flex: unset;
}
.meal-selection-tab-nav-wrap{
  border-bottom: none;
  .nav-item{
    span{
      margin: 0 $pd10;
      padding: $pd10 $pd30;
      border: 1px solid $gray-ccc;
      font-weight: 600;
      cursor: pointer;
      background-color: #fff;
      display: inline-block;
      &:active,&:hover,&.active{
        background-color: $fca-blue-light;
        color: $fca-blue;
        border-color: $fca-blue;
      }
      &:hover{
        opacity: 0.6;
      }

    }
  }
}

.meal-selection-tab-content-wrap{
  .tab-content{
    .tab-pane{

    }
  }
  .day-wrap{
    
    .food_name{
      font-size: $fs14;
      font-weight: 600;
    }

    .food_allergern{
      // color: $red;
      // font-weight: 600;
      font-style: italic;
      font-size: $fs12;
    }

    .food_ingredient{
      color: #999999;
      font-size: $fs12;
    }

    .tea-break-wrap{
      .title-wrap{
        padding-bottom: $pd15;
        .img-container{
          width: $fs25;

          img{
            display: block;
            width: 100%;
          }
        }
        .text-container{
          width: calc(100% - (27px + (50 - 27) * (100vw - 375px) / (1900 - 375)));
          padding-left: $pd15;
          h5{
            margin-bottom: 0;
            font-size: $fs18;
          }
        }
      }

      .content{
        background-color: #eeeeee;
        border: 1px solid $gray-ccc;
        padding: $pd20;
        // padding-bottom: $pd120;
        height: 100%;
        
        
        hr{
            background-color: $fca-blue;
            height: 1px;
            margin: $pd15 0;
        }
        .content-wrap{
          ul{
            margin-bottom: 0;
            li:not(:last-child){
              padding-bottom: $pd15;
            }
          }
        }
      }
    }
    .lunch-wrap{
      .title-wrap{
        .title{
          font-weight: 600;
          font-size: $fs18;
          color: $fca-green;
        }
      }
      .option-wrap{
        // height: 85%;
        label{
          display: block;
          flex: 1;
          position: relative;
          margin-bottom: 0;
          input{
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;

            &:checked{
              + .option-box{
                .option-content{
                  border-color: $fca-blue;
                  background-color: $fca-blue-light;
                }
                
    
                .option-title{
                  // color: #fff;
                  // background-color: $fca-blue;
                }
                .check-tick{
                  .tick{
                    opacity: 1;
                  }
                }
              }
            }
          }
          .option-box{
            height: 100%;
            .option-title{
              font-weight: 600;
              font-size: $fs18;
              color: $fca-blue;
              .title{
  
              }
            }

            .option-content{
              min-height: 90%;
              border: 1px solid $gray-ccc;
              display: flex;
              flex-direction: column;
              cursor: pointer;
              ul{
                padding: $pd20;
                margin-bottom: 0;
                li:not(:last-child){
                  padding-bottom: $pd15;
  
                }
              }

            }
            .check-tick{
              width: $fs30;
              height: $fs30;
              border: 1px solid $gray-ccc;
              border-radius: 50%;
              position: relative;
              margin: auto auto 0;
              .tick{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url("../img/Attendance/check.png");
                background-position: center;
                background-size: cover;
                opacity: 0;
                transition: all .3s ease;  
              }
            }
          }

        }
      }
    }
  }
}

.shuttle-location-wrap{
  .col{

  }
  .shuttle-location-content-wrap{

    .header{
      font-size: $fs18;
      font-weight: 600;
      color: $fca-blue;
        .title{
        }
    }
    .content{
      label{
        position: relative;
        margin: 0;
        cursor: pointer;
        input{
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;

          &:checked{
            + .option-box{
              border-color: $fca-blue;
              background-color: $fca-blue-light;

              .option-title{
                font-weight: bold;
              }
              .check-tick{
                .tick{
                  opacity: 1;
                }
              }
            }
          }
        }
        .option-box {
          border: 1px solid $gray-ccc;
          padding: $pd10;
          margin-bottom: $pd10;
          align-items: center;
            .check-tick{
              width: $fs15;
              height: $fs15;
              border: 1px solid $gray-ccc;
              border-radius: 50%;
              position: relative;
              background-color: #fff;
              .tick{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url("../img/Attendance/check.png");
                background-position: center;
                background-size: cover;
                opacity: 0;
                transition: all .3s ease;  
              }
            }
          .option-title{
            width: calc(100% - 20px);
            padding-left: $pd10;
            .title{

            }
          }

        }
      }
    }
  }
}

.swal2-container{
  .swal2-popup{
    border-radius: 0;
    width: 80%;
    max-width: 700px;
    h2{
      font-size: $fs34;
      font-weight: bold;
      font-family: $font_1;
      color: $body-color;
    }
    .swal2-html-container{
      font-size: $fs16;
      color: $body-color;
    }
    .swal2-confirm{
      display: inline-flex;
        border: 1px solid $fca-green;
        color: $fca-green;
        background-color: $fca-green-light;
        align-items: center;
        min-width: $pd200;
        outline: none !important;
        border-radius: 0;
        box-shadow: none;
    }
    .swal2-cancel{
      display: inline-flex;
        border: 1px solid $fca-red;
        color: $fca-red;
        background-color: $fca-red-light;
        align-items: center;
        min-width: $pd200;
        outline: none !important;
        border-radius: 0;
        box-shadow: none;
    }
  }
}


.main-error-page {
  .container{
    >.row{
      min-height: 100vh;
      
    }
  }
  .img-container{
    max-width: 600px;
    margin: auto;
  }
  .error-container{
    
    .error-head{
      color: $fca-blue;
      font-size: $fs200;
      font-weight: bold;
      line-height: 1;
    }
    p{
    }
    h3,p{
      color: $body-color;
      font-weight: 600;
    }
  }

}

  
.subtotal-div{
  background: rgba(0, 133, 202, 0.1);
  padding:30px;
  width:100%;
  border-top:2px solid rgb(0, 133, 202);
}

.subtotal-div .subtotal-div-first{
  display:flex;
  justify-content: space-between;
}

.subtotal-div .subtotal-div-first{
  font-weight:700;
  font-size:16px;
}
.subtotal-div-gst{
  font-size:12px;
}

.payment-method-div div{
  background: #EEEEEE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin:15px;
  padding:30px;
  color:#101820;
}
.payment-method-div div.active{
  background: #00AB8433;
  color:#00AB84;
  border: 3px solid #01AB84;
}
.payment-method-div div span:first-of-type {
  display:block;
  font-weight:700;
  margin-top: 10px;
}
.loading{
  margin:0 auto;
  width:50%;
  padding-top:10%;
}
.loading .message{
  text-align:center;
}
.loading .note{
  background:#E859411A;
  color:#e85941;
  padding:15px 10px;
  font-size:13px;
  text-align:center;
}
.loading .note span{
  font-weight:700;
}
.select-payment-method{
  font-size:14px;
  font-weight:700;
}
.sub-title{
  font-weight:700;
  text-decoration: underline;
}
.sub-content{
  margin-top:10px;
}
.content_center{
  flex-direction: column-reverse;
  background:#F7F7F7;
  padding:10px;

  @media (min-width: 576px) {
    flex-direction: row;
    padding: 30px;
  }
}

.sub-content ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.countdownDiv{
  padding: 10px 5px;
  font-size: 10px;
  justify-content: flex-end;
}
.countdownDiv span{
  width: 40px;
  display: inline-block;
}


.paynowImgDiv{
  text-align: center;
}
.cert-card{
  .allcourse-title{

  }
  .course-wrap{
    .title{

    }
  }
}
.payment-logo-cc{
  width:100px;
}
.payment-logo-paynow{
  width:50px;
}

.text-color1{
  color:#3DBFB2;
}
.text-color2{
  color:rgb(0, 133, 202);
}
.text-color-dark{
  color: $fca-dark;
}
.background-color1{
  background:#fff8ec;
}
.background-color2{
  background:#e5f6f2;
}

.course-detail-div{
  border:1px solid #00000015;
  border-top:2px solid rgb(0, 133, 202);
}

.learner-detail-div{
  border:1px solid #00000015;
  border-top:2px solid #FFBD43;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.learner-detail-div{
  border:1px solid #00000015;
  border-top:2px solid #CF4D86;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}


.small-desc{
  padding: 10px 10px;
}

.hr-border{
  border-top: 2px solid black;
}

.hr-border2{
  border-top: 1px solid #101820;
}

.success-div{
  background: #e5f3f9;
  justify-content: space-evenly;
  position: relative;
  padding-bottom:20px;
}


// video player
.react-player__shadow{
  background: rgba(0, 0, 0, 0.8) !important;
  border-width: 5px;
  border-style: solid;
  border-color: #fff;
}

.react-player__play-icon {
}




.bg-fca-yellow-light{
  background-color: $fca-yellow-light;
}

.bg-fca-red-light{
  background-color: $fca-red-light;
}

.bg-fca-green-light{
  background-color: $fca-green-light;
}


.sameheight-slider .slick-track{
  display: flex;

  .slick-slide {
      height: auto;
      >div{
          height: 100%;
      }
  }
}

.container-fluid{
  // max-width: 1800px;
}

.course-outline-wrap{
  h5{

  }
  .desc-wrap{
    p{
      // color: $body-color;
      // font-size: $fs16;
      // line-height: 1.5;
    }
  }

  .content-row{
    .col{
      
    }
    .content-wrap{
      position: relative;
      height: 100%;
      .bg{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .1;
      }
      .content-item{
        position: relative;
        z-index: 2;
        padding: dynamic_px(10,50);
        .title-wrap{
          display: flex;
          align-items: center;
          .icon-wrap{
            width: $fs60;
            img{
              width: 100%;
              display: block;
            }
          }
          .title{
            width: calc(100% - (32px + (60 - 32) * (100vw - 375px) / (1900 - 375)));
            padding-left: $pd15;
            h3{
              font-size: $fs18;
              font-weight: bold;
            }
          }
        }
        .desc{
          padding-top: $pd30;
          overflow: hidden;
          p{

          }          
          img{
            width: 100%;
            max-width: 100%;
            display: block;
          }

          table{
            border: none;
            width: 100%;
            table-layout: fixed;
            overflow-wrap: break-word;
            td{
              font-size: $fs16;
              border: none;
              width: 50%;
              ul{
                margin-bottom: 0;
              }
            }
            td[colspan="2"]{
              width: 100%;
            }
          }

          blockquote{
            padding: $pd10 $pd20;
            background-color: #fff;
            border: 1px solid #DDDDDD;

            p::before{
              font-family: "Font Awesome 5 Free";
              -webkit-font-smoothing: antialiased;
              font-weight: 900;              
              content: "\f129";
              color: $fca-blue;
              display: inline-block;
              padding-right: $pd10;
            }
          }
        }
      }
    }
  }

}

.svg_li_wrap{
  ul{
    list-style: none;
    padding-left: 0;
    li{
      padding-left: $pd30;
      position: relative;
      >svg{
        position: absolute;
        top: .2rem;
        left: 0;
        // height: $fs16;
      }
    }
    li +li{
      margin-top: $pd15;
    }
  }
}


.course-calendar-wrap{
  .rdp {
    margin-left: 0;
    margin-right: 0;
  }
  .rdp-months{
    justify-content: center;
  }
  .rdp-day{
    margin: 3px 0;
    pointer-events: none;
    border-radius: $pd15;
    &:hover{
      background-color: rgba(0, 133, 202, 0.5) !important;
      color: #fff;
    }
    &.rdp-day_selected{
      background-color: $fca-blue !important;
      color: #fff;
    }
  }

  .clickable{
    pointer-events: auto;
  }

  .start{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0 !important;
  }
  .middle{
    border-radius: 0;
    border-left: 0 !important;
    border-right: 0 !important;

  }
  .end{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0 !important;

  }

  .normal{
    background-color: $fca-blue-light;
    border: 1px solid $fca-blue;

  }
  .limit{
    background-color: $fca-red-light;
    border: 1px solid $fca-red;
  }

  .rdp-day_selected{
    background-color: $fca-blue;
    color: #fff;
  }
}

.courseRunSelectWrap{
  .courseRunItem{
    margin: $pd10 0;

    .courseRunSelect{
      border: 1px solid $fca-blue;
      padding: $pd15;
      cursor: pointer;

      &.selected{
      background-color: $fca-blue-light;
      }
      .title{
        font-weight: bold;
        font-size: calc(11px + (13 - 11) * (100vw - 375px) / (1900 - 375));
        color: $fca-blue;
      }

      .time{
        font-weight: 600;
        font-size: $fs12;
        color: $fca-blue;
        padding-top: $pd5;
      }
      .trainer{
        display: flex;
        padding-top: $pd5;
        align-items: center;
        .icon{
          width: $fs16;
          height: auto;
          i{
            color: $fca-blue;
            font-size: $fs16;
          }
        }
        .trainer-name{
          font-size: $fs12;
          width: calc(100% - (13px + (16 - 13) * (100vw - 375px) / (1900 - 375)));
          padding-left: $pd10;
        }
        
      }
      .location{
        display: flex;
        padding-top: $pd5;
        align-items: center;
        .icon{
          width: $fs16;
          height: auto;
          i{
            color: $fca-blue;
            font-size: $fs16;
          }

        }
        .location-name{
          font-size: $fs12;
          width: calc(100% - (13px + (16 - 13) * (100vw - 375px) / (1900 - 375)));
          padding-left: $pd10;
        }
      }

    }
  }

  .courseRunItem:nth-child(4n + 1){
    .courseRunSelect{
      border: 1px solid $fca-blue;
      &.selected{
      background-color: $fca-blue-light;
      }
      .title{
        color: $fca-blue;
      }
      .time{
        color: $fca-blue;
      }
      .trainer{
        .icon{
          i{
            color: $fca-blue;
          }
        }
      }
      .location{
        .icon{
          i{
            color: $fca-blue;
          }
        }
      }

    }
  }
  .courseRunItem:nth-child(4n + 2){
    .courseRunSelect{
      border: 1px solid $fca-green;
      &.selected{
      background-color: $fca-green-light;
      }
      .title{
        color: $fca-green;
      }
      .time{
        color: $fca-green;
      }
      .trainer{
        .icon{
          i{
            color: $fca-green;
          }
        }
      }
      .location{
        .icon{
          i{
            color: $fca-green;
          }
        }
      }

    }
  }
  .courseRunItem:nth-child(4n + 3){
    .courseRunSelect{
      border: 1px solid $fca-red;
      &.selected{
      background-color: $fca-red-light;
      }
      .title{
        color: $fca-red;
      }
      .time{
        color: $fca-red;
      }
      .trainer{
        .icon{
          i{
            color: $fca-red;
          }
        }
      }
      .location{
        .icon{
          i{
            color: $fca-red;
          }
        }
      }

    }
  }
  .courseRunItem:nth-child(4n + 4){
    .courseRunSelect{
      border: 1px solid $fca-yellow;
      &.selected{
      background-color: $fca-yellow-light;
      }
      .title{
        color: $fca-yellow;
      }
      .time{
        color: $fca-yellow;
      }
      .trainer{
        .icon{
          i{
            color: $fca-yellow;
          }
        }
      }
      .location{
        .icon{
          i{
            color: $fca-yellow;
          }
        }
      }

    }
  }

}

.course-training-location-container{
  .header-wrap{

    .tab-wrap{
      padding: $pd5;
      .tab-item{
        background-color: #F7F7F7;
        cursor: pointer;
        border-width: 3px;
        border-style: solid;
        border-color: transparent;
        padding: $pd5;
        margin: 0;
        &.active{
          border-color: $fca-green;
        }
      }
      img{
        display: block;
        width: 100%;
      }
      .content{
        .title{
          font-weight: 600;
          font-size: $fs15;
        }
        .address{
          font-size: $fs14;
          color: $body-color;
        }
      }
    }
  }

  .content-wrap{
    .tab-content-wrap{
      .course-run-item-wrap{
        padding: 0 $pd10;
        .course-run-card{
          margin: $pd10 0;
          background-color: $fca-green-light;
          border: 1px solid transparent;
          padding: $pd20;
          cursor: pointer;
          position: relative;
          &.active{
            border-color: $fca-green;
          }
          .limited-course-sticker{
            position: absolute;
            top: 0;
            right: 0;
            background-color: $fca-red;
            color: #fff;
            border-bottom-left-radius: $pd10;
            font-size: $fs12;
            padding: $pd5 $pd15;

          }
          .right-border{
            border-right: 1px solid rgba(0, 171, 132, 0.3);
          }
          &.is_limited{
            background-color: $fca-red-light;
            &.active{
              border-color: $fca-red;
            }
            .right-border{
              border-color: rgba(226, 0, 34, 0.3);
            }
          }
          .date-display{
            width: dynamic_px(35,86);
            height: dynamic_px(40,96);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            text-align: center;
            .date-display-inner{
              .day-of-week{
                font-size: $fs14;
              }
              .day{
                font-weight: 600;
                font-size: $fs46;
                line-height: 1;
              }
            }
          }
          .content{
            padding: $pd20;
            .title{
              font-weight: 600;
              font-size: $fs15;
            }
            .day-range{
              font-size: $fs14;
              color: $body-color;
            }
            .date-range{
              font-size: $fs14;
              color: $body-color;
            }
          }
          .content + .content{
            margin-top: $pd10;
          }
        }
      }
    }
  }

  .month-title{
    font-size: $fs22;
    width: dynamic_px(140, 200);
    font-weight: bold;
    text-align: center;
  }
}

.rating_wrap{
  .rating{
    i{
      color: $fca-yellow;
    }
    i + i {
      margin-left: $pd5;
    }
  }
}

.slick-dots{
  li{
    margin: 0;
    button{

      &::before{
        font-size: dynamic_px(7,10);
        color: #E2E2E2;
        opacity: 1;
      }
    }
    &.slick-active{
      button{
        &::before{
          color: $fca-green;
          opacity: 1;
        }
      }
    }
  }
}

.faq-card{
  padding: $pd20;
  border: 1px solid #DDDDDD;

  .header{
    color: $fca-green;

    .icon-wrap{
      width: $fs16;
      height: $fs16;
      background-color: $fca-green;
      border-radius: 50%;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $fs14;
      line-height: $fs14;
            
    }
  }
  .content{

  }
}

.course-inner-info{
  hr{
    margin: $pd30 0;
  }
}

.mobile-sticky{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99;
}

.mobile-back-button{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: $shadow;
  cursor: pointer;
  i{
    font-size: 18px;
  }
  &.disable{
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
}



.slick-arrow{
    width: $fs30;
    height: $fs30;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: $shadow;
    cursor: pointer;
    &::before{
        font-size: $fs22;
        font-family: "Font Awesome 5 Free";
        -webkit-font-smoothing: antialiased;
        font-weight: 900;
    }
    &.slick-disabled{
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
    &.slick-prev{
      left: -35px;
      &::before{
        content: "\f104";
      }
    }
    &.slick-next{
      right: -35px;
      &::before{
        content: "\f105";
      }
    }
}

.my_course_dashboard_container {
  margin-left: 4rem;
  margin-right: 4rem;
}

.upcoming_course_container {
  display: flex; 
  flex-direction: row;
}
.upcoming_course_card_container {
  display: flex;
  flex-direction: row;
}
.upcoming_course_card_data_container {
  flex: 5;
  flex-direction: column;
  padding: $fs30;
}
.upcoming_course_card_banner_container {
  flex: 5;
}
.upcoming_course_banner_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}

.upcoming_course{
  .course_detail label, .course_detail p, .course-session{
      font-size: $fs15;
  }
}

.bundle_list_banner_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* Add a transition effect to the transform property */
}

.bundle-card {
  box-shadow: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add a transition effect to the transform property */
}
.bundle-card:hover {
  box-shadow: 0px calc(3px + (5 - 3) * (100vw - 375px) / (1900 - 375)) calc(5px + (10 - 5) * (100vw - 375px) / (1900 - 375)) rgba(16, 24, 32, 0.098);
  transform: translateY(-5px); /* Shift the card up when hovered */
}
.bundle-card:hover .bundle_list_banner_image {
  transform: scale(1.05); /* Zoom in the image when the container is hovered */
}

.completed_list_banner_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* Add a transition effect to the transform property */
}
.completed-card {
  box-shadow: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add a transition effect to the transform property */
}
.completed-card:hover {
  box-shadow: 0px calc(3px + (5 - 3) * (100vw - 375px) / (1900 - 375)) calc(5px + (10 - 5) * (100vw - 375px) / (1900 - 375)) rgba(16, 24, 32, 0.098);
  transform: translateY(-5px); /* Shift the card up when hovered */
}
.completed-card:hover .completed_list_banner_image {
  transform: scale(1.05); /* Zoom in the image when the container is hovered */
}

.upcoming-course-info-container {
  padding-left: 7rem;
  padding-right: 7rem;
}

.courseinfo_details_container {
  padding: $fs30;
}

.col-content {
  display: flex;
  flex-direction: column;
}

.upcomingcourse_main_container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.completedcourse_main_container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.2s;
}
.availablecourse_main_container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  transition-delay: 0.2s;
}
.visible {
  opacity: 1 !important;
}

.reschedule_main_container {
  padding-left: 24rem;
  padding-right: 24rem;
}


.bundle-info-container {
  padding-left: 7rem;
  padding-right: 7rem;
}
.bundle_card_container {
  display: flex;
  flex-direction: row;
}
.bundle_card_data_container {
  flex: 5;
  flex-direction: column;
  padding: $fs30;
}
.bundle_card_banner_container {
  flex: 5;
}
.bundle_banner_image {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.bundle_title {
  color: $fca-dark;
  font-size: $fs30;
  font-family: $font_2;
  line-height: 1;
}
.bundle_title_blue {
  color: $fca-blue;
}
.bundle_description {
  color: $fca-dark;
  font-size: $fs15;
}
.bundle_details_container {
  padding: $fs30;
}
.bundle-detail-div{
  border:1px solid #00000015;
  border-top:2px solid #CF4D86;
}
.bundle_details_title {
  color: $fca-dark;
  font-size: $fs18;
}
.bundle_details_title_green {
  color: $fca-green;
  font-weight: 600;
}
.bundle_course_container {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  padding: 1rem;
}
.bundle_course_details_container {
  flex-direction: column;
}
.bundle_course_image_banner_container {
}
.bundle_course_details_inner_container {
  display: flex;
  flex-direction: row;
}
.bundle_course_banner_image {
  width: 120px;
  height: 120px;
  object-fit: cover;
  transition: transform 0.3s ease-in-out; /* Add a transition effect to the transform property */
}
.bundle-course-icon-container{
  margin-top: 5px;
  width: $fs24;
  height: $fs24;
  border: 1px solid $gray-ccc;
  border-radius: 50%;
  position: relative;
  flex: unset;
  max-width: unset;
  img{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }
}
.bundle-course-icon-selected-container{
  margin-top: 5px;
  width: $fs24;
  height: $fs24;
  border: 1px solid $fca-green;
  border-radius: 50%;
  position: relative;
  flex: unset;
  max-width: unset;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bundle-course-selected-circle {
  width: $fs24;
  height: $fs24;
  border-radius: 50%;
  background-color: $fca-green;
}

.bundle-course-card {
  box-shadow: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.5s ease-in-out; /* Add a transition effect to the transform property */
}
.bundle-course-card:hover {
  box-shadow: 0px calc(3px + (5 - 3) * (100vw - 375px) / (1900 - 375)) calc(5px + (10 - 5) * (100vw - 375px) / (1900 - 375)) rgba(16, 24, 32, 0.098);
  transform: translateY(-5px); /* Shift the card up when hovered */
}
.bundle-course-card:hover .bundle_course_banner_image {
  transform: scale(1.05); /* Zoom in the image when the container is hovered */
}
.fadeInDiv {
  // display: none; /* Initially hide the div */
  animation: fadeIn 0.5s ease-in-out forwards; /* Apply the fadeIn animation */
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Start with transparency */
  }
  to {
    display: block; /* Ensure the element remains visible when the animation ends */
    opacity: 1; /* Fade in to full visibility */
  }
}

.divHeightTranstion {
  transition: height 0.5s ease-in-out; /* Add a height transition */
}

.disabled-course-card {
  background-color: #f4f4f4;
  box-shadow: none;
}
