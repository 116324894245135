@import '../../../../assets/scss/custom/variables';

.avatar_main_container {
    padding-bottom: $pd50;
    padding-left: 8rem;
    padding-right: 8rem;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #F4FAFE;
}

.background_image_main_container {
    display: flex;
    flex-direction: row;
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -20;
}
.background_image_left_container {
    flex: 1.5;
}
.background_image_mid_container {
    flex: 7;
}
.background_image_right_container {
    flex: 1.5;
}
.avatar_background_left_image {
    height: auto;
    overflow: hidden;
    width: 100%;
    object-fit: contain;
}

.avatar_card_container {
    background-color: transparent;
    box-shadow: none;
}
// gender button
.gender_main_button_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3rem;
}
.gender_button_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 50px;
    background-color: #DDDDDD;
    border: 1px solid #cccccc;
    transition: background-color 0.3s ease;

    &.selected {
        border: 1px solid #00AB84;
        background-color: #00AB841A;
    }
}
.gender_button_text {
    font-size: $fs14;
    font-weight: 600;
    color: #888;
    transition: color 0.3s ease;

    &.selected {
        color: #00AB84;
    }
}
// selection and display area
.selection_main_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.selection_occupation_container {
    display: flex;
    flex-direction: column;
}

.selection_background_container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.display_main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    // min-height: 330px;
}
.display_background_image {
    height: auto;
    overflow: hidden;
    width: 70%;
    object-fit: contain;
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
}
.display_occupation_image {
    height: 90%;
    overflow: hidden;
    width: auto;
    object-fit: contain;
    position: absolute;
    bottom: -14px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
}
.fade_in {
    opacity: 1;
}
  
.fade_out {
    opacity: 0;
}

.avatar_background__middle_image {
    height: auto;
    overflow: hidden;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
}
.display_occupation_details_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 3rem;
}
.display_occupation_description_text {
    font-size: $fs30;
    color: $fca-dark;
    margin-bottom: 2px;
    font-family: $font_2;
}
.display_occupation_title_text {
    font-size: $fs24;
    color: $fca-dark;
}
.avatar_selection_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
}
.avatar_selection_title_text {
    flex: 2.5;
    color: $fca-dark;
    font-size: $fs16;
    font-weight: 600;
    margin-right: 10px;
}
// avatar list selection page
.avatar_listing_selection_title_text {
    color: $fca-dark;
    font-size: $fs16;
    font-weight: 600;
}
.avatar_listing_selection_main_container {
    transition: opacity 0.3s ease-in-out; /* Smooth transition */
}
.avatar_listing_selection_container {
    display: flex;
}
.avatar_listing_image_container {
    width: 100px;
    height: 100px;
    margin-right: 8px;
    margin-bottom: 8px;
}
.avatar_listing_image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;

    &.selected {
        border: 1px solid #0085CA;
    }
}

// avatar carousel selection page
.avatar_carousel_selection_container {
    flex: 7.5;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
}
.avatar_carousel_scrollview_container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.image-scroll-view::-webkit-scrollbar {
    display: none; /* WebKit */
}
.avatar_carousel_image_container {
    display: inline-flex;
    padding: 10px;
}
.avatar_carousel_image_container img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin-right: 10px;
    -webkit-user-drag: none;
    user-select: none;
    width: 100px;
    height: 100px;
}
.avatar_carousel_arrow_left_container {
    position: absolute;
    left: -10px;
    z-index: 10;
}
.avatar_carousel_arrow_right_container {
    position: absolute;
    right: -10px;
    z-index: 10;
}
.avatar_carousel_arrow_container {
    width: $fs45;
    height: $fs45;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: $shadow;
    cursor: pointer;
    i{
        font-size: $fs26;

    }
    &.disable{
        opacity: 0.5;
        cursor: default;
        pointer-events: none;
    }
    &.arrow-left{

    }
    &.arrow-right{

    }
}

// responsive
@media (max-width: 1499px) {
    .avatar_main_container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .avatar_listing_image_container {
        width: 90px;
        height: 90px;
    }
}
@media (max-width: 1399px) {
    .avatar_main_container {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .avatar_listing_image_container {
        width: 90px;
        height: 90px;
    }
    .display_background_image {
        width: 70%;
    }
}
@media (max-width: 1299px) {
    .avatar_main_container {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .avatar_listing_image_container {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .display_background_image {
        width: 70%;
    }
}

@media (max-width: 1199px) {
    .avatar_main_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .avatar_listing_image_container {
        width: 80px;
        height: 80px;
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .display_background_image {
        width: 80%;
    }
}

@media (max-width: 1099px) {
    .avatar_main_container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .avatar_listing_image_container {
        width: 70px;
        height: 70px;
        margin-right: 8px;
        margin-bottom: 8px;
    }
    .display_background_image {
        width: 80%;
    }
}

@media (max-width: 991px) {
    .avatar_main_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .avatar_listing_image_container {
        width: 60px;
        height: 60px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
    .display_background_image {
        width: 80%;
    }
}

@media (max-width: 767px) {
    .avatar_main_container {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .avatar_listing_image_container {
        width: 60px;
        height: 60px;
        margin-right: 4px;
        margin-bottom: 4px;
    }
    .display_background_image {
        width: 70%;
    }
}

@media (max-width: 575px) {
    .avatar_main_container {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .background_image_left_container {
        display: none;
    }
    .background_image_right_container {
        display: none;
    }
    .display_background_image {
        width: 90%;
    }
    .display_occupation_details_container {
        margin-top: 1rem;
    }
}

