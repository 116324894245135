$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$font-size-xs:                ($font-size-base * .75);

$black:    #000 !default;
$gray-500: #adb5bd !default;
$gray-aaa: #AAAAAA !default;
$blue:    rgb(55,132,196) !default;

/* In your CSS */
.test {
    font-size: $font-size-lg;
  }

.notification-title {
    color: $black;
    font-weight: bold;
}

.notification-description {
    color: $black;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.notification-date {
    font-size: $font-size-sm;
    color: $gray-aaa;
}

.notification-bundle-title {
    color: $black;
}

.notification-bundle-description {
    font-weight:600;
    color: $blue;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.notification-comment-image {
    height: 80px;
    width: 80px;
    object-fit:cover;
}

.notification-details-title {
    font-size: $font-size-lg;
    color: $black;
    font-weight: bold;
}

.notification-details-description {
    color: $black;
    white-space: pre-wrap;
}

.notification-details-date {
    font-size: $font-size-sm;
    color: $gray-aaa;
}

.eye-icon {
    color: $gray-aaa;
    font-size: 24px;
 }

.notification-details-button-responded {
    color: $black;
    font-weight: bold;
    align-self: center;
}
