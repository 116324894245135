@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "../../../../assets/scss/custom/functions";
@import "../../../../assets/scss/custom/variables";

.profile_title {
    font-size: $fs40;
    color: $fca-dark;
    font-family: $font_2;
}
.profile_header_card {
    margin-bottom: 1.5rem !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.profile_header_cardbody {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // background-color: #0085CA20;
    background: linear-gradient(0deg, #0085CA25 0%, #00AB8420 100%) !important;
}
.profile_background_image {
    height: auto;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}
.profile_avatar_container {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 3px solid #FFFFFF;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.profile_avatar_background_image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
}
.profile_avatar_occupation_image {
    height: 180%;
    width: auto;
    object-fit: cover;
    position: absolute;
    bottom: -87%;
}
.profile_name_text {
    font-size: $fs24;
    color: $fca-dark;
    font-weight: 600;
    font-family: $font_1;
}
.profile_email_text {
    font-size: $fs15;
    color: $fca-blue;
    font-family: $font_1;
}
.profile_description_text {
    font-size: $fs15;
    color: $fca-dark;
    font-family: $font_1;
}
.profile_qr_container {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-left: 1rem;
}
.profile_icon_main_container {
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
}
.profile_icon_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;
}
.profile_icon_title {
    font-size: $fs15;
    color: $fca-dark;
    font-family: $font_1;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    white-space: pre-line;
}
.profile_icon_title_blue {
    font-size: $fs15;
    color: $fca-blue;
    font-family: $font_1;
    font-weight: bold;
    margin-right: 4px;
}




.profile_qr_modal_close_container {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    cursor: pointer;
    width: 3rem;
    height: 3rem;
    align-items: center;
    align-self: flex-end;
    position: absolute;
}
.profile_qr_modal_close_icon {
    font-size: 1.5rem;
    color: $fca-dark
}
.profile_qr_modal_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    padding-top: 2rem;
    margin-Bottom: 0.5rem;
}
.profile_qr_modal_header_title {
    font-size: $fs40;
    color: $fca-blue;
    font-family: $font_2;
}
.profile_qr_modal_tnc_title {
    font-size: $fs16;
    color: $fca-dark;
    font-family: $font_1;
}

.qrContainer {
    background-color: #fff;
    position: relative;
    margin-top: -19rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    margin-bottom: 0.5rem;
}
.frame_text {
    font-size: $fs12;
    color: $fca-dark;
    margin-bottom: 1.5rem;
}
.name_text {
    font-size: $fs18;
    color: $fca-dark;
    font-weight: bold;
}
.code_text {
    font-size: $fs16;
    color: $fca-dark;
}
.planeContainer {
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.horizontalLine {
    flex-grow: 1;
    height: 1px;
    background-color: #0085CA;
}
.attendance_text {
    font-size: $fs15;
    color: $fca-dark;
    font-weight: 600;
    margin-bottom: 0;
}
.scan_text {
    font-size: $fs15;
    color: $fca-dark;
    margin-bottom: 0;
}

// earth animation
.earth_container {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    justify-content: center;
    .earth_image {
      width: 80%;
      height: auto;
    }
    // moving animation 1
    .rotate_1 {
      animation: rotation infinite 60s linear;
    }
    @keyframes rotation {
      from {transform:rotate(0deg);}
      to {transform:rotate(360deg);}
    }
}

// profile bundle
.profile_bundle_desc_text {
    font-size: $fs16;
    font-family: $font_1;
    color: $fca-dark;
    flex-wrap: wrap;
}
.profile_bundle_certificate_bundle_title {
    font-size: $fs16;
    font-family: $font_1;
    font-weight: bold;
    color: $fca-dark;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;
}
.profile_bundle_certificate_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.profile_bundle_certificate_image_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    width: 180px;
}
.profile_bundle_certificate_course_title {
    font-size: $fs14;
    font-family: $font_1;
    color: $fca-dark;
    flex-wrap: wrap;
    margin-top: 6px;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}
.profile_bundle_badge_container {
    height: 100px;
    width: 100px;
}
.profile_bundle_badge_image_container {
    height: 80px;
    width: 80px;
    object-fit: contain;
}
.profile_bundle_badge_number_container {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 10;
}
.profile_bundle_badge_number_text {
    color: #fff;
    font-size: $fs14;
    font-weight: 500;
    text-align: center;
}

@media (max-width: 1499px) {
   
}
@media (max-width: 1399px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
    .earth_container {
        .earth_image {
          width: 90%;
          height: auto;
        }
    }
    .qrContainer {
        margin-top: -15rem;
    }
    .profile_icon_main_container {
       justify-content: space-between;
    }
    .profile_icon_container {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 452px) {
    .profile_bundle_certificate_container {
        justify-content: center;
    }
}