@import '../../../../../assets/scss/custom/variables';

.checkout_success_container {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-bottom: 100px;
}
.success_title_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.success_course_container {
    background-color: white;
    margin-top: 40px;
    padding: 32px;
    display: flex;
    flex-direction: column;
}

//shiny animation
.wrap {
    display: inline-block;
    height: 270px;
    width: 310px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    margin-right: 30px;

}
.wrap img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.wrap:before {
    content: "";
    z-index: 10;
    position: absolute;
    height: 200%;
    width: 200%;
    top: -120%;
    left: -120%;
    background: linear-gradient(transparent 0%, rgba(255, 255, 255, 0.3) 45%, rgba(255, 255, 255, 0.7) 50%, rgba(255, 255, 255, 0.3) 55%, transparent 100%);
    transition: all 4s;
    transform: rotate(-45deg);
    animation: shine 6s infinite forwards;
}
@keyframes shine {
    0% {
      top: -120%;
      left: -120%;
    }
    20% {
      left: 100%;
      top: 100%;
    }
    40% {
      left: 100%;
      top: 100%;
    }
    100% {
      left: 100%;
      top: 100%;
    }
}


.success_icon {
    width: 310px;
    height: 270px;
    margin-bottom: 30px;
}
.title_text {
    font-size: $fs28;
    font-weight: 600;
    color: #101820;
    text-align: center;
    margin-bottom: 10px;
}

.desc_text {
    font-size: $fs14;
    color: #101820;
    text-align: center;
    max-width: 75%;
}

.sfc_text {
    border-radius: $border-radius;
    font-size: $fs14;
    text-align: center;
    background-color:  transparent;
    color:black;
    font-weight: 600;
}


// course
.course_logo_container {
    display: flex;
    flex-direction: row;
}
.course_logo {
    display: initial;
    margin: 0.25rem;
    width: 40px;
}
.course_title {
    font-size: $fs20;
    font-weight: 600;
    color: #101820;
    margin-top: 0.25rem;
}
.course_info_wrap{
    margin-bottom: 2.5rem;
}
.course_info_title_container {
    display: flex;
    align-items: center;
}
.course_info_title_icon {
    height: 16px;
    width: 16px;
    margin-right: 0.5rem;
}
.course_info_title_icon2 {
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}
.course_info_title {
    font-size: $fs16;
    font-weight: 600;
    color: #101820;
}
.course_info_description {
    font-size: $fs16;
    color: #101820;
}
.course_info_trainer_wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
}
.course_info_trainer_title_container {
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
}
.course_info_trainer_image {
    display: initial;
    margin: 0.25rem;
    width: 60px;
    object-fit: contain;
}
.course_trainer_info_description {
    font-size: $fs16;
    color: #AAAAAA;
}
// button
.button_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
}
.button {
    padding: 1rem !important;
    padding-left: 2rem  !important;
    padding-right: 2rem !important;
}

//download
.download_container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-top: 2rem;
    background: linear-gradient(90deg, $fca-green-light 0, $fca-blue-light 100%) !important;
    padding: 3rem;
}
.download_image_container {
    display: flex;
    flex: 4.5;
    position: relative;
    justify-content: center;
    align-items: center;
}
.download_button_container {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}
.download_floating_image_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.download_floating_image_container > * {
    position: absolute;
    width: 5vw;
}
.download_floating_image{
    height: 100%;
    width: 100%;
}
.thumb_icon {
    border-radius: 50%;
}
.download_floating_image_container >.item_1 {
    width: 8%;
    right: 20%;
    top: 10%;
}
.download_floating_image_container >.item_2 {
    width: 8%;
    left: 0%;
    top: 40%;
}
.download_floating_image_container >.item_3 {
    width: 8%;
    left: 35%;
    bottom: 12%;
}
.download_floating_image_container >.item_4 {
    width: 8%;
    right: 10%;
    bottom: 5%;
}
.download_floating_image_container >.item_5 {
    width: 8%;
    right: 0%;
    bottom: 30%;
}
.scale_animation_1 {
    animation: scaleAnimation1 2s infinite alternate;
}
@keyframes scaleAnimation1 {
    from {
        transform: scale(1.0);
      }
    to {
        transform: scale(1.2);
    }
}
.scale_animation_2 {
    animation: scaleAnimation1 3s infinite alternate;
}
@keyframes scaleAnimation2 {
    from {
        transform: scale(1.0);
      }
    to {
        transform: scale(1.2);
    }
}



.download_falcomm_container {
    display: flex;
    flex-direction: column;
    flex: 5.5;
    position: relative;
}
.download_phone_image {
    height: auto;
    max-width: 80%;
    width: 100%;
    z-index: 100;
    object-fit: contain;
}
.download_falcomm_title_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    @media (min-width: 524px) {
        flex-wrap: nowrap;
    }
}

.download_fca_image_container {
    display: flex;
    padding: 8px;
    background-color: white;
    border-radius: 8px;
    align-self: flex-start;
    margin-right: 1rem;
}
.download_fca_image {
    height: auto;
    width: 70px;
    object-fit: contain;
}
.download_title_text_container {
    display: flex;
    flex-direction: column;
}
.downoad_title {
    font-size: $fs26;
    color: $fca-blue;
    font-weight: 600;
    margin-bottom: 0.5rem;
}
.download_desc {
    font-size: $fs14;
    color: #101820;
}
.download_benefit_title {
    font-size: $fs14;
    color: #101820;
    font-weight: 600;   
    margin-bottom: 5px;
}
.download_benefit_desc_container {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}
.download_benefit_desc {
    font-size: $fs14;
    color: #101820;
}
.check_container {
    display: flex;
    background-color: $fca-green;
    padding: 4px;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    border-radius: 50%;
    margin-right: 5px;
}
.check_icon {
    color: white;
    font-size: 10px;
}
.apple_download_img {
    height: auto;
    width: 160px;
    object-fit: contain;
    margin-right: 10px;
    z-index: 1000;
    cursor: pointer;
}
.google_download_img {
    height: auto;
    width: 160px;
    object-fit: contain;
    z-index: 1000;
    cursor: pointer;
}

// checkout success floating
.success_account_container {
    position: relative;
    .vector_animate_ele {
      transition: all 0.5s ease;
      align-self: center;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 424px;
      width: 300px;
    }
    .vector_animate_wrap > * {
      position: absolute;
      width: 5vw;
    }
    .vector_animate_ele > * {
      position: absolute;
    }
    .banner_1_item_image {
      height: 100%;
      width: 100%;
    }
    .vector_animate_wrap >.item_1 >.backgroundCloud {
        width: 100%;
        height: 100%;
        background: url(../../../../../assets/img/Checkout/Success/success_bg.svg);
        background-repeat: repeat;
        background-position: 0 0;
        background-size: auto 100%;
        animation: animatedBackground 500s linear infinite;
    }
    @keyframes animatedBackground {
        from {
          background-position: 0 0;
        }
      /*use negative width if you want it to flow right to left else and positive for left to right*/
        to {
          background-position: -10000px 0;
        }
      }
    .background_container {
        border-radius: 50%;
        width: 300px;
        height: 300px;
        background-color: #101820;
    }
    .vector_animate_wrap >.item_1 {
      overflow: hidden;
      z-index: 10;
    }
    .vector_animate_wrap >.item_2 {
        width: 60%;
        left: 18%;
        bottom: 16%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_3 {
        width: 28%;
        left: 62%;
        bottom: 28%;
        z-index: 10;
    }
    .vector_animate_wrap >.item_4 {
        width: 16%;
        left: 104%;
        bottom: 46%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_5 {
        width: 25%;
        left: 1%;
        bottom: 64%;
        z-index: 10;
    }
    .vector_animate_wrap >.item_6 {
        width: 17%;
        left: 60%;
        bottom: 58%;
        z-index: 20;
    }
    .vector_animate_wrap >.item_7 {
        width: 23%;
        left: 3%;
        bottom: 17%;
        z-index: 20;
    }

    .arrow_animation {
        animation-name : arrowmove;
        animation-iteration-count : infinite;
        animation-direction: normal;
        animation-duration : 3s;
        opacity: 1;
    }
    @keyframes arrowmove {
        0% {
            transform: translate(0px, 0px) scale(1.4);
            opacity: 1;
        }

        20% {
            transform: translate(-85px, 37px) scale(1.0);
            opacity: 1;
        }
      
        90% {
            transform: translate(-85px, 37px) scale(1.0);
            opacity: 1;
        }
        100% {
            transform: translate(-85px, 37px);
            opacity: 0;
        }
    }
    .scale_animation_2 {
      animation: scaleAnimation2 0.5s infinite alternate;
    }
    .scale_animation_3 {
      animation: scaleAnimation2 1s infinite alternate;
    }
     .scale_animation_4 {
      animation: scaleAnimation2 2s infinite alternate;
    }
    @keyframes scaleAnimation2 {
        from {
            transform: scale(1.0);
          }
        to {
            transform: scale(1.2);
        }
    }

    .rotate_1 {
        animation: rotation infinite 3s linear;
      }
      @keyframes rotation {
        from {transform:rotate(0deg);}
        to {transform:rotate(360deg);}
      }
    .rotate_2 {
        animation: rotation2 infinite 1s alternate;
      }
      @keyframes rotation2 {
        from {transform:rotate(0deg);}
        to {transform:rotate(-10deg);}
      }
  
  
    .moving_horizontal_1 {
      animation-name : horizontalmove1;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
    }
    @keyframes horizontalmove1 {
      from {transform: translateX(0px);}
      to {transform: translateX(20px);}
    }
    .moving_horizontal_2 {
      animation-name : horizontalmove2;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
      animation-delay: 0.5s;
    }
    @keyframes horizontalmove2 {
      from {transform: translateX(0px);}
      to {transform: translateX(20px);}
    }
    .moving_horizontal_3 {
      animation-name : horizontalmove2;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
      animation-delay: 1s;
    }
    @keyframes horizontalmove3 {
      from {transform: translateX(0px);}
      to {transform: translateX(20px);}
    }
  
    .moving_vertical_1 {
      animation-name : verticalmove1;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
    }
    .moving_vertical_2 {
      animation-name : verticalmove1;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
      animation-delay: 0.5s;
    }
    .moving_vertical_3 {
      animation-name : verticalmove1;
      animation-iteration-count : infinite;
      animation-direction:alternate;
      animation-duration : 2s;
      animation-delay: 1s;
    }
    @keyframes verticalmove1 {
      from {transform: translateY(0px);}
      to {transform: translateY(20px);}
    }
  }

// responsive
@media (max-width: 1318px) {
    .success_course_container {
        padding: 32px;
    }
}

@media (max-width: 980px) {
    .success_course_container {
        padding: 24px;
    }
    .course_info_wrap{
        margin-bottom: 1rem;
    }
    .button_container {
        display: flex;
        flex-direction: column;
    }
    .checkout_success_container {
        margin-left: 8%;
        margin-right: 8%;
    }
}

@media (max-width: 767px) {
    .success_course_container {
        padding: 18px;
    }
    .course_info_wrap{
        margin-bottom: 1rem;
    }
    .download_container {
        flex-direction: column;
    }
    .checkout_success_container {
        margin-left: 5%;
        margin-right: 5%;
    }
    .download_container {
        padding: 1rem;
    }
    .download_button_container {
        flex-direction: column;
    }
    .apple_download_img {
        height: auto;
        width: 100%;
        object-fit: contain;
        margin-right: 10px;
        z-index: 1000;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .google_download_img {
        height: auto;
        width: 100%;
        object-fit: contain;
        z-index: 1000;
        cursor: pointer;
    }
}

