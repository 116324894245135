@import '../../../../assets/scss/custom/variables';

// banner
.banner_container {

}
.banner_bg {
    background-image: url(../../../../assets/img/Home/BottomBanner/bg.jpg);
}

.item_1 {
    width: 5%;
    left: 0%;
    bottom: 13%;
}
.item_2 {
    width: 4%;
    left: 13%;
    bottom: 45%;
}
.item_3 {
    width: 3%;
    left: 5%;
    bottom: 60%;
}
.item_4 {
    width: 4%;
    left: 15%;
    bottom: 70%;
}
.item_5 {
    width: 20%;
    left: 25%;
    bottom: 33%;
}
.item_6 {
    width: 10%;
    left: 40%;
    top: -10%;
}
.item_7 {
    width: 4%;
    left: 50%;
    bottom: 45%;
}
.item_8 {
    width: 4%;
    right: 0%;
    top: 0%;
}
.item_9 {
    width: 5%;
    right: -10%;
    top: 30%;
}

// banner text
.banner_1_title {
    font-weight:700;
    font-size: $fs30;
}

// responsive
@media (max-width: 1318px) {
    .banner_1_title {
        font-weight:700;
        font-size: $fs27;
    }
}

@media (max-width: 991px) {
    .banner_1_title {
        font-weight:700;
        font-size: $fs24;
    }
    .banner_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .banner_1_title {
        font-weight:700;
        font-size: $fs21;
    }
    .banner_container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}