
.react-tabs {
    -webkit-tap-highlight-color: transparent;
  
    &__tab-list {
        flex: 1;
        flex-direction:row;
        display:flex;
        border-bottom: 1px solid rgb(55,132,196);
        margin: 0 0 10px;
        padding: 0;
        padding-top: 10px;
        background: #fff;
    }
  
    &__tab {
        flex: 1;
        justify-content:center;
        display: flex;
        position: relative;
        padding: 6px 12px;
        cursor: pointer;
        color:rgb(204,204,204);
      &--selected {
        border-bottom: 4px solid rgb(55,132,196);
        color: black;
        border-radius: 5px 5px 0 0;
      }
  
      &--disabled {
        color: GrayText;
        cursor: default;
      }
  
      &:focus {
        outline: none;
  
        // &:after {
        //   content: "";
        //   position: absolute;
        //   height: 5px;
        //   left: -4px;
        //   right: -4px;
        //   bottom: -5px;
        //   background: #fff;
        // }
      }
    }
  
    &__tab-panel {
      display: none;
  
      &--selected {
        display: block;
      }
    }
}

.home-bundle-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
      flex: 1;
      flex-direction:row;
      display:flex;
      // border-bottom: 1px solid rgb(55,132,196);
      margin: 0 0 10px;
      padding: 0;
      padding-top: 10px;
      background: #fff;
      stroke: black;
      margin-bottom: 0;
  }

  &__tab {
      flex: 1;
      justify-content:center;
      align-items: center;
      display: flex;
      position: relative;
      padding: 25px 6px;
      cursor: pointer;
      color: 'black';
      background-color: rgb(221,221,221);
      border-right: 1px solid #cccccc;
      border-top: 4px solid transparent;
      font-weight: 600;
      &--selected {
      border-image: linear-gradient(0.25turn, #00ab84, rgb(55,132,196));
      border-image-slice: 1;
      background-color: rgb(247,247,247);
      stroke: none;
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      outline: none;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   height: 5px;
      //   left: -4px;
      //   right: -4px;
      //   bottom: -5px;
      //   background: #fff;
      // }
    }
  }

  &__tab-panel {
    // display: none;

    &--selected {
      display: block;
    }
  }
}

  