// particle flowing around the container
// example:
// <div className="container">
//     <span />
//     <span />
//     <span />
//     <span />
//     <span />
//     <span />
//     <span />
// </div>
$particleSize: 2vmin;
$animationDuration: 6s;
$amount: 20;

.floatParticlesContainer span {
    width: $particleSize;
    height: $particleSize;
    border-radius: $particleSize;
    backface-visibility: hidden;
    position: absolute;
    animation-name: move;
    animation-duration: $animationDuration;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    $colors: (
        rgba(255,255,255, 0.6),
    );
    @for $i from 1 through $amount {
        &:nth-child(#{$i}) {
        color: nth($colors, random(length($colors)));
        top: random(100) * 1%;
        left: random(100) * 1%;
        animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
        animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
        transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
        $blurRadius: (random() + 0.5) * $particleSize * 0.5;
        $x: if(random() > 0.5, -1, 1);
        box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
        }
    }
}

@keyframes move {
    100% {
        transform: translate3d(0, 0, 1px) rotate(360deg);
    }
}
///////////////////////////////////////////

// logo shining and transform to certain degree when hover
// example:
// <div className="shining_img_container">
//      <img />
// </div>
.shining_img_container {
  -webkit-mask-image: linear-gradient(45deg,#fff 25%,rgba(255,255,255,.2) 50%,#fff 75%);
  mask-image: linear-gradient(45deg,#fff 25%,rgba(255,255,255,.2) 50%,#fff 75%);
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
  transform: perspective(400px) rotate3d(1,-1,0,calc(var(--i,1)*0deg));
  transition: transform 2s ease;
}

.shining_img_container:hover {
  transition: mask-position 2s ease,-webkit-mask-position 2s ease, transform 2s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
  transform: perspective(400px) rotate3d(1,-1,0,calc(var(--i,1)*8deg));
}
///////////////////////////////////////////

// particles flowing slowly from bottom of the screen
// example:
// <div className={styles.reverse_snow_animation_wrapper}>
//     <div className={`${styles.particle} ${styles.particle_1}`}></div>
//     <div className={`${styles.particle} ${styles.particle_2}`}></div>
//     <div className={`${styles.particle} ${styles.particle_3}`}></div>
//     <div className={`${styles.particle} ${styles.particle_4}`}></div>
// </div>
// animation
/* Config */
$color-particle: rgba(255,255,255,0.4);
$spacing: 2560px;
$time-1: 60s;
$time-2: 120s;
$time-3: 180s;
$time-4: 600s;

/* Pauls awesome mixin */
@function particles($max) {
   $val: 0px 0px $color-particle;
   @for $i from 1 through $max {
      $val: #{$val},
      random($spacing)+px random($spacing)+px $color-particle;
   }
   @return $val;
}

@mixin particles($max) {
   box-shadow: particles($max);
}

/* Styles */
.reverse_snow_animation_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.particle,
.particle:after {
  background: transparent;
}
.particle:after {
  position: absolute;
  content: "";
  top: $spacing;
}
.particle-_1 {
  animation: animParticle $time-1 linear infinite;
  @include particles(600);
  height: 1px;
  width: 1px;
}
.particle_1:after {
  @include particles(600);
  height: 1px;
  width: 1px;
}
.particle_2 {
  animation: animParticle $time-2 linear infinite;
  @include particles(200);
  height: 2px;
  width: 2px;
}
.particle_2:after {
  @include particles(200);
  height: 2px;
  width: 2px;
}
.particle_3 {
  animation: animParticle $time-3 linear infinite;
  @include particles(100);
  height: 3px;
  width: 3px;
}
.particle_3:after {
  @include particles(100);
  height: 3px;
  width: 3px;
}
.particle_4 {
  animation: animParticle $time-4 linear infinite;
  @include particles(400);
  height: 1px;
  width: 1px;
}
.particle_4:after {
  @include particles(400);
  height: 1px;
  width: 1px;
}
@keyframes animParticle {
  from { transform: translateY(0px); }
  to   { transform: translateY($spacing * -1); }
}
///////////////////////////////////////////
