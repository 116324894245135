@import "~bootstrap/scss/functions";
@import "../../custom/functions";
@import "../../custom/mixins";
@import "../../custom/variables";

.login_wrap{
    height: 100%;
    .login_left_col{
        background-image: url("../../../img/Login/bg.png");
        background-size: cover;
        background-position: center;
        display: flex;
        height: 100%;
        overflow: hidden;
        flex-direction: column;
        .img_container{
          display: flex;
          justify-content: flex-end;
          height: fit-content;
          margin: 50px;
          margin-bottom: 0px;
        }
    }

    .login_right_col{
        height: 100%;
        overflow-y: scroll;
        .wrap{
            min-height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;;
        }
    }
}

.title_container{
    text-align: center;
    padding-bottom: $pd30;
    
    &::after{
        position: relative;
        content: '';
        background-image: url("../../../img/Login/storke.png");
        background-size: contain;
        background-position: center;
        width: $pd160;
        height: $pd10;
        display: block;
        margin: auto;
    }
}

.submit_button{
    text-align: center;
    min-width: $pd300;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.logo_image {
  height: auto;
  width: 210px;
}

.title_text {
  font-size: $fs40;
  color: $fca-dark;
  font-family: $font-2;
  line-height: 1em;
  margin-bottom: 0px;
}

.desc_text {
  font-size: $fs48;
  color: $fca-yellow;
  font-family: $font-4;
  margin-bottom: 10px;
  margin-top: 10px;
}

.input_container {
  height: 60px;
}

// floating girl1
.main_girls_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.girl1_container {
  display: flex;
  flex: 5;
  align-items: center;
  position: relative;
  .girl1_image {
    height: auto;
    max-width: 45%;
    width: 100%;
    margin-left: 50px;
  }
  // floating items animation
  .vector_animate_ele {
    position: absolute;
    margin-left: 50px;
    transition: all 0.5s ease;
    align-self: center;
  }
  .vector_animate_wrap > * {
    position: absolute;
    width: 5vw;
  }
  .vector_animate_ele > * {
    position: absolute;
  }
  .banner_1_item_image {
    height: 100%;
    width: 100%;
  }
  .vector_animate_wrap >.item_1 {
    width: 10%;
    left: 10%;
    bottom: 90%;
  }
  .vector_animate_wrap >.item_2 {
    width: 20%;
    left: 0%;
    bottom: 63%;
  }
  .vector_animate_wrap >.item_3 {
    width: 12%;
    right: 30%;
    bottom: 100%;
  }
  .vector_animate_wrap >.item_4 {
    width: 15%;
    left: 15%;
    bottom: 10%;
  }
  .vector_animate_wrap >.item_5 {
    width: 15%;
    left: -3%;
    bottom: 37%;
  }
  .vector_animate_wrap >.item_6 {
    width: 18%;
    right: 6%;
    bottom: 35%;
  }
  .vector_animate_wrap >.item_7 {
    width: 7%;
    right: 30%;
    bottom: 8%;
  }
  // moving animation 1
  .moving_vertical_1 {
    animation-name : mymove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
  }
  @keyframes mymove1 {
    from {transform: translateY(0px);}
    to {transform: translateY(20px);}
  }
  .moving_vertical_2 {
    animation-name : mymove2;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 2s;
  }
  @keyframes mymove2 {
      from {transform: translateY(30px);}
      to {transform: translateY(0px);}
  }
  .moving_vertical_3 {
      animation-name : mymove3;
      animation-iteration-count : infinite;
      animation-direction : alternate;
      animation-duration : 3s;
  }
  @keyframes mymove3 {
      from {transform: translateY(10px);}
      to {transform: translateY(0px);}
  }
  .rotate_1 {
    animation: rotation infinite 3s linear;
  }
  @keyframes rotation {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
}

// floating girl2
.girl2_container {
  display: flex;
  justify-content: flex-end;
  flex: 5;
  align-items: flex-start;
  position: relative;
  .girl2_image {
    height: auto;
    max-width: 45%;
    width: 100%;
    margin-right: 50px;
  }
  // floating items animation
  .vector_animate_ele {
    position: absolute;
    margin-right: 50px;
    transition: all 0.5s ease;
  }
  .vector_animate_wrap > * {
    position: absolute;
    width: 5vw;
  }
  .vector_animate_ele > * {
    position: absolute;
  }
  .banner_1_item_image {
    height: 100%;
    width: 100%;
  }
  .vector_animate_wrap >.item_1 {
    width: 6%;
    left: 12%;
    bottom: 25%;
  }
  .vector_animate_wrap >.item_2 {
    width: 25%;
    right: -2%;
    bottom: 70%;
  }
  .vector_animate_wrap >.item_3 {
    width: 10%;
    right: 15%;
    bottom: 95%;
  }
  .vector_animate_wrap >.item_4 {
    width: 15%;
    right: 17%;
    bottom: 10%;
  }
  .vector_animate_wrap >.item_5 {
    width: 20%;
    left: 20%;
    bottom: 10%;
  }
  .vector_animate_wrap >.item_6 {
    width: 20%;
    left: 6%;
    bottom: 70%;
  }
  .vector_animate_wrap >.item_7 {
    width: 5%;
    right: 3%;
    bottom: 30%;
  }
  // moving animation 1
  .moving_vertical_1 {
    animation-name : mymove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
  }
  @keyframes mymove1 {
    from {transform: translateY(0px);}
    to {transform: translateY(20px);}
  }
  .moving_vertical_2 {
    animation-name : mymove2;
    animation-iteration-count : infinite;
    animation-direction : alternate;
    animation-duration : 2s;
  }
  @keyframes mymove2 {
      from {transform: translateY(30px);}
      to {transform: translateY(0px);}
  }
  .moving_vertical_3 {
      animation-name : mymove3;
      animation-iteration-count : infinite;
      animation-direction : alternate;
      animation-duration : 3s;
  }
  @keyframes mymove3 {
      from {transform: translateY(10px);}
      to {transform: translateY(0px);}
  }
  .rotate_1 {
    animation: rotation infinite 3s linear;
  }
  @keyframes rotation {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
}

@media (max-width: 1500px) {
  .girl1_container {
    .girl1_image {
      height: auto;
      max-width: 55%;
      width: 100%;
      margin-left: 50px;
    }
  }
  .girl2_container {
    .girl2_image {
      height: auto;
      max-width: 55%;
      width: 100%;
      margin-right: 50px;
    }
  }
}

@media (max-width: 1300px) {
  .girl1_container {
    .girl1_image {
      height: auto;
      max-width: 55%;
      width: 100%;
      margin-left: 30px;
    }
    .vector_animate_ele {
      margin-left: 30px;
    }
  }
  .girl2_container {
    .girl2_image {
      height: auto;
      max-width: 55%;
      width: 100%;
      margin-right: 30px;
    }
    .vector_animate_ele {
      margin-right: 30px;
    }
  }
}

@media (max-width: 1199px) {
  .login_wrap{
    .login_left_col{
        .img_container{
            margin: 20px;
            margin-bottom: 0px;
        }
    }

    .login_right_col{
        .wrap{
        }
    }

  }
  .girl1_container {
    .girl1_image {
      height: auto;
      max-width: 65%;
      width: 100%;
      margin-left: 10px;
    }
    .vector_animate_ele {
      margin-left: 10px;
    }
  }
  .girl2_container {
    .girl2_image {
      height: auto;
      max-width: 65%;
      width: 100%;
      margin-right: 10px;
    }
    .vector_animate_ele {
      margin-right: 10px;
    }
  }
}

@media (max-width: 991px) {
  .login_wrap{
      .login_left_col{
          height: auto;
          .img_container{
              margin: 30px;
              margin-bottom: 0px;
              justify-content: center;
          }
      }

      .login_right_col{
          height: auto;
      overflow-y: visible;
          .wrap{
              min-height: none;
              padding: $pd80 0;
          }
      }

  }
  .main_girls_container {
    flex-direction: row;
  }
  .girl1_container {
    justify-content: center;
    .girl1_image {
      height: auto;
      max-width: 60%;
      width: 100%;
      margin-left: 0px;
    }
    .vector_animate_ele {
      margin-left: 0px;
    }
  }
  .girl2_container {
    justify-content: center;
    align-items: center;
    .girl2_image {
      height: auto;
      max-width: 60%;
      width: 100%;
      margin-right: 0px;
    }
    .vector_animate_ele {
      margin-right: 0px;
    }
  }
}

@media (max-width: 767px) {
    .login_wrap{
        .login_left_col{
            .img_container{
                margin: 20px;
                margin-bottom: 0px;
                justify-content: center;
                img{
                }
            }
        }
    
        .login_right_col{
            .wrap{
            }
        }
    
    }
}

@media (max-width: 575px) {
  .main_girls_container {
    flex-direction: column;
  }
  .girl1_container {
    justify-content: center;
    margin-top: 40px;
    .girl1_image {
      height: auto;
      max-width: 60%;
      width: 100%;
      margin-left: 0px;
    }
    .vector_animate_ele {
      margin-left: 0px;
    }
  }
  .girl2_container {
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    .girl2_image {
      height: auto;
      max-width: 60%;
      width: 100%;
      margin-right: 0px;
    }
    .vector_animate_ele {
      margin-right: 0px;
    }
  }
}

@media (max-width: 400px) {
  .main_girls_container {
    flex-direction: column;
  }
  .girl1_container {
    justify-content: center;
    margin-top: 0px;
    .girl1_image {
      height: auto;
      max-width: 70%;
      width: 100%;
      margin-left: 0px;
    }
    .vector_animate_ele {
      margin-left: 0px;
    }
  }

  .girl2_container {
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 30px;
    .girl2_image {
      height: auto;
      max-width: 70%;
      width: 100%;
      margin-right: 0px;
    }
    .vector_animate_ele {
      margin-right: 0px;
    }
  }
}

@media (min-width: 768px) and (max-width: 980px) {
    
}

@media (min-width: 981px) and (max-width: 1199px) {
    
}

@media (min-width: 1200px) and (max-height: 850px) {
}

@media (min-width: 1200px) and (max-width: 1450px) {
    
}
