.referral_redeem_modal {
    padding: 4rem;
}
.form-section .react-tabs__tab {
    background-color: #ddd;
    color: #0C2236;
    padding: 14px;
    font-family: Coolvetica;
    font-size: 18px;
    font-weight: bold;
}

.contact_form_tab .react-tabs__tab.react-tabs__tab--selected {
    background-color: #fff;
    color: #00ab84;
    border: 2px solid #00ab84;
    border-radius: 0px;
}

.contact_form_tab {
    display: flex;
    padding-left: 0;
}

.form-section {
    background-color: #FFFFFF;
    padding-top: 1em;
}

.contact_header {
    font-size: 26px;
    font-family: 'Coolvetica';
    text-align: center;
    padding: 0 2em;
    color: #101820;
}

.contact_header_colored {
    color: #00ab84;
}

.contact_banner {
    display: flex;
    flex: 1;
    position: relative;
    background-color: rgb(228,243,250);
    padding-top: 2rem;
    flex-direction: row;
    overflow: hidden;
}
.contact_image_banner {
    width: 50%;
}

#individual-contact-form {
    padding-left: 2em;
    padding-right: 2em;
}

.form_content,
.react-tabs__tab-list,
.form_content .card {
    background-color: #FFFFFF;
}

.form-section .react-tabs__tab-list {
    padding: 0 2em;
    border-bottom: 0;
}

.form-control-label span {
    font-weight: normal;
}

.contact-form-close .close.close-black {
    display: none;
}

.close-black {
    z-index: 100;
}

.contact-form-close {
    padding: 0;
    background-color: #FFFFFF;
}

.contact-form-close .close {
    position: absolute;
    right: -50px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.consent-label a {
    color: #00ab84
}

.consent-label a:hover {
    color: #233dd2;
}

.referral_datatable_table {
    border: 1px solid #DDDDDD;
}
.datatable_table_header {
    background-color: rgb(0, 133, 202);
}
.datatable_header_th {
    text-transform: none;
    font-family: "Poppins", "sans-serif";
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    padding: 1rem 2rem;
}
.datatable_table_body {
    background-color: #f8f8f8;
}
.datatable_table_body_td {
    padding: 1rem 2rem;
    font-size: 14px;
    color: #101820;
}

.table_status_container {
    display: inline-block;
    background-color: #E5F7F2;
    border-radius: 5px;
    padding: 3px 1rem;
}
.table_status_container_orange {
    display: inline-block;
    background-color: rgba(243, 190, 91, 0.1);
    border-radius: 5px;
    padding: 3px 1rem;
}
.table_status_container_red {
    display: inline-block;
    background-color: rgba(226, 0, 34, 0.1);
    border-radius: 5px;
    padding: 3px 1rem;
}
.table_status_container_grey {
    display: inline-block;
    background-color: #DDDDDD;
    border-radius: 5px;
    padding: 3px 1rem;
}
.table_status_text {
    font-size: 12px;
    color: #00ab84;
}
.table_status_text_orange {
    font-size: 12px;
    color: #FFBD43;
}
.table_status_text_red {
    font-size: 12px;
    color: #e20021;
}
.table_status_text_grey {
    font-size: 12px;
    color: #101820;
}

.datatable_checkbox_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
}
.datatable_checkbox_input {
    position: static;
    margin: 0;
    width: 16px;
    height: 16px;
}

.green_hyperlink {
    color: #00AB84;
    text-decoration: underline;
  }

@media (max-width: 1280px) {
    .contact-form-close .close.close-black {
        display: block;
    }
    .contact-form-close .close.close-white {
        display: none;
    }
    .contact-form-close .close {
        right: 20px;
    }
}

@media (max-width: 768px) {
    .react-tabs__tab-list {
        padding: 0;
    }
    .contact-form-close .close {
        right: 20px;
    }
    .contact_header {
        padding: 0;
    }
    .contact-form-close {
        background-color: #FFFFFF;
    }
    .form_content .contact-submit {
        justify-content: center !important;
        padding-right: 0 !important;
    }
    .referral_redeem_modal {
        padding: 2rem;
    }
    .contact_image_banner {
        width: 70%;
    }
    
}

@media (max-width: 575px) {
    .referral_redeem_modal {
        padding: 1rem;
    }
    .contact_image_banner {
        width: 90%;
    }
}