

.main-header-container {
    position: sticky;
    top: 0px;
    z-index: 1040;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    display: flex;
    justify-content: center;
}

.main-header-container.hidden {
    visibility: hidden;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    transform: translateY(-100%);
    opacity: 0;
}

.main-header-container.active {
    visibility: visible;
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    opacity: 1;
}

.navbar-logo {
    height: 100%;
    padding-top: 5px;
    z-index: 200;
}

.main-header-nav {
    flex: 1;
    justify-content: center;
}

.nav-container {
    width: 90%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    .navbar-brand {
        z-index: 200;

    }
}

.nav-collapse-container {
    // display: flex;
    // justify-content: center;
    // position: absolute;
    // width: 100%;
}

.nav-collapse-container{
    .navbar-nav{
        .nav-item{
            .nav-link  {
                color: #101820;
                font-size: medium;
                font-family: "Poppins",sans-serif;
                font-weight: 300;
            }
        }
    }
}

.nav-collapse-navlink-login {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 0;
    align-self: center;
    .nav-link {
        align-self: center;
        color: rgb(170, 170, 170) !important;
    }
}

.notification-number-container {
    position: absolute;
    left: 10px;
    top: -8px;
}
.notification-number-container-button {
    background-color: #00ab84;
    padding: 2px;
    height: 20px;
    width: auto;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right: 5px;
    border-radius: 9999px;
}
.notification-number-text {
    font-size: 10px;
    font-weight: 600;
    color: white;
}


  .fade-in-out-div {
    width: 320px;
    position: absolute;
    top: 30px;
    left: -180px;
    opacity: 0;
    transform: translateY(-20px); /* Initial position above the container */
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    background-color: #00ab84;
    padding: 10px;
    margin-top: 10px;
    z-index: 100000;
  }
  
  .fade-in-out-div.show {
    opacity: 1;
    transform: translateY(0); /* Translate to the original position */
  }
  