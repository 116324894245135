@import '../../../../assets/scss/custom/variables';

////////// bundle
.home_bundle_container {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    background-color: white;
}
.visible {
    opacity: 1 !important;
}
.home_bundle_title_container {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: row;
}
.home_bundle_image_container {
    display: flex;
    flex: 5;
    position: relative;
    padding-top: 40px;
    justify-content: center;
}
.home_bundle_text_container {
    display: flex;
    flex: 5;
    position: relative;
    align-items: center;
    padding-top: 100px;
}
.home_bundle_listing_container {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 80px;
    margin-right: 80px;
}
.home_bundle_skill_image {
    height: auto;
    max-width: 70%;
    width: 100%;
    z-index: 100;
}
.home_bundle_title {
    font-weight:700;
    color: #011932;
    font-size: $fs40;
}
.home_bundle_title_blue {
    color: $fca-blue;
}
.home_bundle_title_green {
    color: $fca-green;
}
.home_bundle_text_background_container {
    position: absolute;
    width: 100%;
    top: 0;
}
.home_bundle_text_background_container >.background_1 {
    position: absolute;
    width: 50%;
    right: 0%;
    top: 10%;
}
// bundle floating icon
.home_bundle_floating_container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    max-width: 70%;
    margin-top: 40px;
}
.home_bundle_floating_container > * {
    position: absolute;
    width: 5vw;
}
.home_bundle_lottie{
    height: 100%;
    width: 100%;
}
.home_bundle_floating_image{
    height: 100%;
    width: 100%;
}
.home_bundle_floating_container >.item_animation_1 {
    width: 25%;
    left: 2%;
    top: 2%;
}
.home_bundle_floating_container >.item_1 {
    width: 7%;
    left: -5%;
    top: 35%;
}
.home_bundle_floating_container >.item_2 {
    width: 7%;
    left: -8%;
    top: 12%;
}
.home_bundle_floating_container >.item_3 {
    width: 7%;
    right: -2%;
    top: 20%;
}
.home_bundle_floating_container >.item_4 {
    width: 7%;
    left: 30%;
    top: -5%;
}
.home_bundle_floating_container >.item_5 {
    width: 7%;
    right: 35%;
    top: -6%;
}
.home_bundle_floating_container >.item_6 {
    width: 7%;
    right: -5%;
    bottom: 25%;
}
.home_bundle_floating_container >.item_7 {
    width: 7%;
    right: 12%;
    top: 0%;
}
.home_bundle_floating_container >.item_8 {
    width: 7%;
    right: -10%;
    bottom: 50%;
}
.home_bundle_floating_container >.item_9 {
    width: 7%;
    left: -10%;
    bottom: 30%;
}
.bundle_moving_animation_1 {
    animation-name : bundlemove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 3s;
}
@keyframes bundlemove1 {
    from {transform: translateY(0px);}
    to {transform: translateY(20px);}
}
.bundle_moving_animation_2 {
    animation-name : bundlemove2;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 3s;
}
@keyframes bundlemove2 {
    from {transform: translateY(20px);}
    to {transform: translateY(0px);}
}
.bundle_moving_animation_3 {
    animation-name : bundlemove3;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 4s;
}
@keyframes bundlemove3 {
    from {transform: translateY(0px);}
    to {transform: translateY(10px);}
}

// bundle tab
.home_bundle_tab_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.home_bundle_tab_image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    justify-content: flex-start;
    align-items: flex-end;
}
.home_bundle_tab_text {
    color: #101820;
    font-weight: 600;
    font-size: $fs25;
}
.home_bundle_tab_detail_container {
    background-color: rgb(247,247,247);
    padding: 50px;
    padding-left: 90px;
    padding-right: 90px;
}
.home_bundle_tab_detail_title {
    font-size: $fs18;
    color: #101820;
    text-align: center;
    margin-bottom: 30px;
}
// bundle list
.home_bundle_card {
    padding-right: 20px;
    background: rgb(247,247,247);
    box-shadow: none;
    margin-bottom: 0;
    cursor: pointer;
}
.home_bundle_list_image {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    box-shadow: $shadow;
}
.home_bundle_list_title {
    font-size: $fs22;
    color: #101820;
    font-weight: 600;
    margin-bottom: 5px;
}
.home_bundle_list_button {
    font-size: $fs18;
    color: $fca-green;
}

// responsive
@media (max-width: 1318px) {
    .home_bundle_title {
        font-size: $fs36;
    }
    .home_bundle_tab_text {
        font-size: $fs23;
    }
    .home_bundle_tab_image {
        width: 46px;
        height: 46px;
    }
    .home_bundle_list_title {
        font-size: $fs20;
    }
    .home_bundle_list_button {
        font-size: $fs16;
        color: $fca-green;
    }
    .home_bundle_tab_detail_container {
        padding-left: 60px;
        padding-right: 60px;
    }
}

@media (max-width: 991px) {
    .home_bundle_title {
        font-size: $fs33;
    }
    .home_bundle_tab_text {
        font-size: $fs21;
    }
    .home_bundle_tab_image {
        width: 42px;
        height: 42px;
    }
    .home_bundle_image_container {
        margin-left: 60px;
    }
    .home_bundle_listing_container {
        margin-left: 60px;
        margin-right: 60px;
    }
    .home_bundle_tab_detail_container {
        padding-left: 40px;
        padding-right: 40px;
    }
    .home_bundle_list_title {
        font-size: $fs18;
    }
    .home_bundle_list_button {
        font-size: $fs14;
        color: $fca-green;
    }
}

@media (max-width: 767px) {
     // bundle
    .home_bundle_title_container {
        flex-direction: column;
    }
    .home_bundle_title {
        font-size: $fs30;
        margin-left: 50px;
        margin-right: 50px;
    }
    .home_bundle_image_container {
        margin-left: 0px;
    }
    .home_bundle_listing_container {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        margin-right: 0px;
    }
    .home_bundle_text_container {
        padding-top: 50px;
        padding-bottom: 50px;
        justify-content: center;
    }
    .home_bundle_tab_container {
        flex-direction: column;
    }
    .home_bundle_tab_text {
        font-size: $fs19;
    }
    .home_bundle_tab_image {
        width: 38px;
        height: 38px;
        margin-bottom: 5px;
        margin-right: 0px;
    }
    .home_bundle_tab_detail_container {
        padding-left: 20px;
        padding-right: 20px;
    }
    .home_bundle_list_title {
        font-size: $fs16;
    }
    .home_bundle_list_button {
        font-size: $fs14;
        color: $fca-green;
    }
}

