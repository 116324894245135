.fs14{font-size: $fs14;}
.fs15{font-size: $fs15;}
.fs16{font-size: $fs16;}
.fs17{font-size: $fs17;}
.fs18{font-size: $fs18;}
.fs19{font-size: $fs19;}
.fs20{font-size: $fs20;}
.fs21{font-size: $fs21;}
.fs22{font-size: $fs22;}
.fs23{font-size: $fs23;}
.fs24{font-size: $fs24;}
.fs25{font-size: $fs25;}
.fs26{font-size: $fs26;}
.fs27{font-size: $fs27;}
.fs28{font-size: $fs28;}
.fs29{font-size: $fs29;}
.fs30{font-size: $fs30;}
.fs31{font-size: $fs31;}
.fs32{font-size: $fs32;}
.fs33{font-size: $fs33;}
.fs34{font-size: $fs34;}
.fs35{font-size: $fs35;}
.fs36{font-size: $fs36;}
.fs37{font-size: $fs37;}
.fs38{font-size: $fs38;}
.fs39{font-size: $fs39;}
.fs40{font-size: $fs40;}
.fs41{font-size: $fs41;}
.fs42{font-size: $fs42;}
.fs43{font-size: $fs43;}
.fs44{font-size: $fs44;}
.fs45{font-size: $fs45;}
.fs46{font-size: $fs46;}
.fs47{font-size: $fs47;}
.fs48{font-size: $fs48;}
.fs49{font-size: $fs49;}
.fs50{font-size: $fs50;}
.fs55{font-size: $fs55;}
.fs60{font-size: $fs60;}
.fs64{font-size: $fs64;}
.fs65{font-size: $fs65;}
.fs72{font-size: $fs72;}
.fs75{font-size: $fs75;}
.fs80{font-size: $fs80;}
.fs84{font-size: $fs84;}
.fs85{font-size: $fs85;}
.fs100{font-size: $fs100;}
.fs200{font-size: $fs200;}

.pdt5{padding-top: $pd5;}
.pdt10{padding-top: $pd10;}
.pdt15{padding-top: $pd15;}
.pdt20{padding-top: $pd20;}
.pdt25{padding-top: $pd25;}
.pdt30{padding-top: $pd30;}
.pdt35{padding-top: $pd35;}
.pdt40{padding-top: $pd40;}
.pdt45{padding-top: $pd45;}
.pdt50{padding-top: $pd50;}
.pdt60{padding-top: $pd60;}
.pdt70{padding-top: $pd70;}
.pdt80{padding-top: $pd80;}
.pdt90{padding-top: $pd90;}
.pdt100{padding-top: $pd100;}
.pdt110{padding-top: $pd110;}
.pdt120{padding-top: $pd120;}
.pdt130{padding-top: $pd130;}
.pdt140{padding-top: $pd140;}
.pdt150{padding-top: $pd150;}
.pdt160{padding-top: $pd160;}
.pdt170{padding-top: $pd170;}
.pdt180{padding-top: $pd180;}
.pdt190{padding-top: $pd190;}
.pdt200{padding-top: $pd200;}
.pdt250{padding-top: $pd250;}

.pdb5{padding-bottom: $pd5;}
.pdb10{padding-bottom: $pd10;}
.pdb15{padding-bottom: $pd15;}
.pdb20{padding-bottom: $pd20;}
.pdb25{padding-bottom: $pd25;}
.pdb30{padding-bottom: $pd30;}
.pdb35{padding-bottom: $pd35;}
.pdb40{padding-bottom: $pd40;}
.pdb45{padding-bottom: $pd45;}
.pdb50{padding-bottom: $pd50;}
.pdb60{padding-bottom: $pd60;}
.pdb70{padding-bottom: $pd70;}
.pdb80{padding-bottom: $pd80;}
.pdb90{padding-bottom: $pd90;}
.pdb100{padding-bottom: $pd100;}
.pdb110{padding-bottom: $pd110;}
.pdb120{padding-bottom: $pd120;}
.pdb130{padding-bottom: $pd130;}
.pdb140{padding-bottom: $pd140;}
.pdb150{padding-bottom: $pd150;}
.pdb160{padding-bottom: $pd160;}
.pdb170{padding-bottom: $pd170;}
.pdb180{padding-bottom: $pd180;}
.pdb190{padding-bottom: $pd190;}
.pdb200{padding-bottom: $pd200;}
.pdb250{padding-bottom: $pd250;}

.pdl5{padding-left: $pd5;}
.pdl10{padding-left: $pd10;}
.pdl15{padding-left: $pd15;}
.pdl20{padding-left: $pd20;}
.pdl25{padding-left: $pd25;}
.pdl30{padding-left: $pd30;}
.pdl35{padding-left: $pd35;}
.pdl40{padding-left: $pd40;}
.pdl45{padding-left: $pd45;}
.pdl50{padding-left: $pd50;}
.pdl60{padding-left: $pd60;}
.pdl70{padding-left: $pd70;}
.pdl80{padding-left: $pd80;}
.pdl90{padding-left: $pd90;}
.pdl100{padding-left: $pd100;}
.pdl110{padding-left: $pd110;}
.pdl120{padding-left: $pd120;}
.pdl130{padding-left: $pd130;}
.pdl140{padding-left: $pd140;}
.pdl150{padding-left: $pd150;}
.pdl160{padding-left: $pd160;}
.pdl170{padding-left: $pd170;}
.pdl180{padding-left: $pd180;}
.pdl190{padding-left: $pd190;}
.pdl200{padding-left: $pd200;}
.pdl250{padding-left: $pd250;}
.pdl5{padding-left: $pd5;}
.pdl10{padding-left: $pd10;}
.pdl15{padding-left: $pd15;}
.pdl20{padding-left: $pd20;}
.pdl25{padding-left: $pd25;}
.pdl30{padding-left: $pd30;}
.pdl35{padding-left: $pd35;}
.pdl40{padding-left: $pd40;}
.pdl45{padding-left: $pd45;}
.pdl50{padding-left: $pd50;}
.pdl60{padding-left: $pd60;}
.pdl70{padding-left: $pd70;}
.pdl80{padding-left: $pd80;}
.pdl90{padding-left: $pd90;}
.pdl100{padding-left: $pd100;}
.pdl110{padding-left: $pd110;}
.pdl120{padding-left: $pd120;}
.pdl130{padding-left: $pd130;}
.pdl140{padding-left: $pd140;}
.pdl150{padding-left: $pd150;}
.pdl160{padding-left: $pd160;}
.pdl170{padding-left: $pd170;}
.pdl180{padding-left: $pd180;}
.pdl190{padding-left: $pd190;}
.pdl200{padding-left: $pd200;}
.pdl250{padding-left: $pd250;}

.pdr5{padding-right: $pd5;}
.pdr10{padding-right: $pd10;}
.pdr15{padding-right: $pd15;}
.pdr20{padding-right: $pd20;}
.pdr25{padding-right: $pd25;}
.pdr30{padding-right: $pd30;}
.pdr35{padding-right: $pd35;}
.pdr40{padding-right: $pd40;}
.pdr45{padding-right: $pd45;}
.pdr50{padding-right: $pd50;}
.pdr60{padding-right: $pd60;}
.pdr70{padding-right: $pd70;}
.pdr80{padding-right: $pd80;}
.pdr90{padding-right: $pd90;}
.pdr100{padding-right: $pd100;}
.pdr110{padding-right: $pd110;}
.pdr120{padding-right: $pd120;}
.pdr130{padding-right: $pd130;}
.pdr140{padding-right: $pd140;}
.pdr150{padding-right: $pd150;}
.pdr160{padding-right: $pd160;}
.pdr170{padding-right: $pd170;}
.pdr180{padding-right: $pd180;}
.pdr190{padding-right: $pd190;}
.pdr200{padding-right: $pd200;}
.pdr250{padding-right: $pd250;}

.pd5{padding: $pd5;}
.pd10{padding: $pd10;}
.pd15{padding: $pd15;}
.pd20{padding: $pd20;}
.pd25{padding: $pd25;}
.pd30{padding: $pd30;}
.pd35{padding: $pd35;}
.pd40{padding: $pd40;}
.pd45{padding: $pd45;}
.pd50{padding: $pd50;}
.pd60{padding: $pd60;}
.pd70{padding: $pd70;}
.pd80{padding: $pd80;}
.pd90{padding: $pd90;}
.pd100{padding: $pd100;}
.pd110{padding: $pd110;}
.pd120{padding: $pd120;}
.pd130{padding: $pd130;}
.pd140{padding: $pd140;}
.pd150{padding: $pd150;}
.pd160{padding: $pd160;}
.pd170{padding: $pd170;}
.pd180{padding: $pd180;}
.pd190{padding: $pd190;}
.pd200{padding: $pd200;}
.pd250{padding: $pd250;}
.pdr250{padding: $pd250;}

.pd0-inner *{
    padding: 0 !important;
}

.pd0{
    padding: 0 !important;
}

.pdt0{
    padding-top: 0 !important;
}

.pdb0{
    padding-bottom: 0 !important;
}

.flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
}
.w50{width: 50%;}
.w33{width: 33.33%;}
.w25{width: 25%;}

.img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}



@media (max-width: 980px) {
    .flex{flex-wrap: wrap;}
    .w50{width: 50%;}
    .w25{width: 50%;}
}


@media (max-width: 767px) {
    .w50{width: 100%;}
    .w33{width: 50%;}
}

@media (max-width: 575px) {
    .w50{width: 100%;}
    .w33{width: 100%;}
    .w25{width: 100%;}
}

.font_1{font-family: $font_1;}
.font_2{font-family: $font_2;}
.font_3{font-family: $font_3;}
.font_4{font-family: $font_4;}

.text-fca-dark{color: $fca-dark;}