
@import '../../assets/scss/custom/variables';

.course_logo_container {
    display: flex;
    flex-direction: row;
}
.course_logo {
    display: initial;
    margin: 0.25rem;
    width: 40px;
}
.course_title {
    font-size: $fs20;
    font-weight: 600;
    color: #101820;
    margin-top: 0.25rem;
}
.course_info_wrap{
    margin-bottom: 2.5rem;
}
.course_info_title_container {
    display: flex;
    align-items: center;
}
.course_info_title_icon {
    height: 16px;
    width: 16px;
    margin-right: 0.5rem;
}
.course_info_title_icon2 {
    height: 20px;
    width: 20px;
    margin-right: 0.5rem;
}
.course_info_title {
    font-size: $fs16;
    font-weight: 600;
    color: #101820;
}
.course_info_description {
    font-size: $fs16;
    color: #101820;
}
.course_info_trainer_wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 2.5rem;
}
.course_info_trainer_image {
    display: initial;
    margin: 0.25rem;
    width: 60px;
    object-fit: contain;
}
.course_info_trainer_title_container {
    display: flex;
    flex-direction: column;
    margin-left: 0.25rem;
}
.course_trainer_info_description {
    font-size: $fs16;
    color: #AAAAAA;
}


// responsive
@media (max-width: 1318px) {
}

@media (max-width: 980px) {
    .course_info_wrap{
        margin-bottom: 1rem;
    }
}

@media (max-width: 767px) {
    .course_info_wrap{
        margin-bottom: 1rem;
    }
}
