@import "../../../assets/scss/custom/variables";

.root {
  background-color: white;
  padding: 20px;
}

.section_header{
  font-size: large;
  font-weight: bold;
  margin-bottom: 20px;
}

.alert {
  background-color: #E7F2F9;
  color: #7C89E2;
}


.login_modal_header {
  font-size: large;
}

.login_modal_col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 40px;
}

.login_modal_img {
  object-fit: cover;
  width: 100%;
}


.successModalContainer {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.successHeading {
  text-align: center;
  font-size: $fs31;
}


.successCongrats {
  font-family: $font_4;
  color: #F6BF5B;
  margin-right: 10px;
}

.successMessage{
  text-align: center;
  font-size: $fs20;
}

.login_button_container {
  display: flex;
  flex-direction: row;
}

.singpass_login_container {
  // flex: 6;
  max-width: 330px;
  
}

.button_wrap.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;

}

.self_login_container {
  flex: 4;
}

.button_wrap {
  cursor: pointer;
  margin: 1.5rem;
}

.user_login_btn {
  height: 50px;
  border-radius: 8px;
  border: 1px solid #4BA58C;
  color: $fca-dark;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: white;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: flex;

}
.singpass_login_button {
  width: 100%;
  height: auto;
}

// success create account modal animation
.success_account_container {
  position: relative;
  .vector_animate_ele {
    transition: all 0.5s ease;
    align-self: center;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 424px;
    width: 400px;
  }
  .vector_animate_wrap > * {
    position: absolute;
    width: 5vw;
  }
  .vector_animate_ele > * {
    position: absolute;
  }
  .banner_1_item_image {
    height: 100%;
    width: 100%;
  }
  .vector_animate_wrap >.item_1 {
    width: 80%;
  }
  .vector_animate_wrap >.item_2 {
    width: 40%;
    left: 25%;
    bottom: 40%;
    z-index: 20;
  }
  .vector_animate_wrap >.item_3 {
    width: 65%;
    left: 10%;
    bottom: 29%;
    z-index: 10;
  }
  .vector_animate_wrap >.item_4 {
    width: 70%;
    left: 13%;
    bottom: 21%;
  }
  .vector_animate_wrap >.item_5 {
    width: 28%;
    right: 15%;
    bottom: 75%;
  }
  .vector_animate_wrap >.item_6 {
    width: 17%;
    right: 7%;
    bottom: 53%;
  }
  .vector_animate_wrap >.item_7 {
    width: 19%;
    left: 0%;
    bottom: 53%;
  }
  .vector_animate_wrap >.item_8 {
    width: 4%;
    left: 15%;
    bottom: 77%;
    z-index: 20;
  }
  .vector_animate_wrap >.item_9 {
    width: 3%;
    left: 23%;
    bottom: 44%;
    z-index: 20;
  }
  .vector_animate_wrap >.item_10 {
    width: 3%;
    right: 33%;
    bottom: 54%;
    z-index: 20;
  }
  .vector_animate_wrap >.item_11 {
    width: 20%;
    left: 9.5%;
    bottom: 24%;
    transform: rotate(257deg);
    z-index: 100;
  }
  .vector_animate_wrap >.item_12 {
        width: 5%;
    left: 38.5%;
    bottom: 62%;
    z-index: 20;
  }

  .scale_animation_1 {
    animation: scaleAnimation1 2s infinite alternate;
  }
  @keyframes scaleAnimation1 {
      from {
          transform: scale(1.0);
        }
      to {
          transform: scale(1.1);
      }
  }
  .scale_animation_2 {
    animation: scaleAnimation2 1s infinite alternate;
  }
  .scale_animation_3 {
    animation: scaleAnimation2 1.5s infinite alternate;
  }
   .scale_animation_4 {
    animation: scaleAnimation2 2s infinite alternate;
  }
  @keyframes scaleAnimation2 {
      from {
          transform: scale(1.0);
        }
      to {
          transform: scale(1.4);
      }
  }

  .moving_horizontal_1 {
    animation-name : horizontalmove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
  }
  @keyframes horizontalmove1 {
    from {transform: translateX(0px);}
    to {transform: translateX(20px);}
  }
  .moving_horizontal_2 {
    animation-name : horizontalmove2;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
    animation-delay: 0.5s;
  }
  @keyframes horizontalmove2 {
    from {transform: translateX(0px);}
    to {transform: translateX(20px);}
  }
  .moving_horizontal_3 {
    animation-name : horizontalmove2;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
    animation-delay: 1s;
  }
  @keyframes horizontalmove3 {
    from {transform: translateX(0px);}
    to {transform: translateX(20px);}
  }

  .moving_vertical_1 {
    animation-name : verticalmove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
  }
  .moving_vertical_2 {
    animation-name : verticalmove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
    animation-delay: 0.5s;
  }
  .moving_vertical_3 {
    animation-name : verticalmove1;
    animation-iteration-count : infinite;
    animation-direction:alternate;
    animation-duration : 2s;
    animation-delay: 1s;
  }
  @keyframes verticalmove1 {
    from {transform: translateY(0px);}
    to {transform: translateY(20px);}
  }

  .star_animation1 {
    animation-name : staranimation1;
    animation-iteration-count : infinite;
    animation-direction: normal;
    animation-duration : 6s;
  }
  @keyframes staranimation1 {
    0% {
        transform: translate(0px, 0px) scale(1.4) rotate(0deg);
        opacity: 1;
    }
    40% {
        transform: translate(95px, 62px) scale(1) rotate(360deg);
        opacity: 1;
    }
    60% {
      transform: translate(95px, 62px) scale(0) rotate(360deg);
      opacity: 0;
    }
    100% {
      transform: translate(95px, 62px) scale(0) rotate(360deg);
      opacity: 0;
    }
  }
  .star_animation2 {
    animation-name : staranimation2;
    animation-iteration-count : infinite;
    animation-direction: normal;
    animation-duration : 6s;
  }
  @keyframes staranimation2 {
    0% {
        transform: translate(0px, 0px) scale(1.4) rotate(0deg);
        opacity: 1;
    }
    40% {
        transform: translate(64px, -79px) scale(1) rotate(360deg);
        opacity: 1;
    }
    60% {
      transform: translate(64px, -79px) scale(0) rotate(360deg);
      opacity: 0;
    }
    100% {
      transform: translate(64px, -79px) scale(0) rotate(360deg);
      opacity: 0;
    }
  }
  .star_animation3 {
    animation-name : staranimation3;
    animation-iteration-count : infinite;
    animation-direction: normal;
    animation-duration : 6s;
  }
  @keyframes staranimation3 {
    0% {
        transform: translate(0px, 0px) scale(1.4) rotate(0deg);
        opacity: 1;
    }
    40% {
        transform: translate(-100px, -37px) scale(1) rotate(360deg);
        opacity: 1;
    }
    60% {
      transform: translate(-100px, -37px) scale(0) rotate(360deg);
      opacity: 0;
    }
    100% {
      transform: translate(-100px, -37px) scale(0) rotate(360deg);
      opacity: 0;
    }
  }
  .star_animation4 {
    animation-name : staranimation4;
    animation-iteration-count : infinite;
    animation-direction: normal;
    animation-duration : 6s;
  }
  @keyframes staranimation4 {
    0% {
        transform: scale(0) rotate(0deg);
        opacity: 0;
    }
    30% {
      transform: scale(0) rotate(0deg);
      opacity: 0;
    }
    50% {
      transform: scale(1.2) rotate(0deg);
      opacity: 1;
    }
    80% {
        transform: scale(1.6) rotate(0deg);
        opacity: 1;
    }
    100% {
      transform: scale(0) rotate(360deg);
      opacity: 0;
    }
  }
}
.successModalImage {
  max-width: 500px;
  width: 100%;
}

// responsive
@media (max-width: 1318px) {
  .singpass_login_button {
    max-width: 330px;
  }
}

@media (max-width: 980px) {
  .singpass_login_button {
    max-width: 330px;
  }
}

@media (max-width: 767px) {
  .login_button_container {
    display: flex;
    flex-direction: column;
  }
  .button_wrap {
    margin-bottom: 0.5rem;
  }
  .singpass_login_button {
    max-width: none;
  }
  .vector_animate_ele {
      height: 424px !important;
  }
}

@media (max-width: 400px) {
  .login_button_container {
    display: flex;
    flex-direction: column;
  }
  .button_wrap {
    margin-bottom: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media (min-width: 767px) {
  .button_wrap {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }
}
