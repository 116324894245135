.grid {
	width: 900px;
	max-width: 100%;
	display: grid;
	margin: 0 auto;
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-auto-rows: 1fr;
	grid-auto-flow: row dense;
	grid-gap: 10px;
	counter-reset: boxes;
}

.box {
	position: relative;
	color: rgba(255, 255, 255, 0.7);
	font-size: 2rem;
	font-family: sans-serif;
	counter-increment: boxes;
	overflow: hidden;
	border-radius: 10px;

	&::before {
		content: "";
		display: block;
		width: 100%;
		padding-top: 100%;
	}

	// &::after {
	// 	position: absolute;
	// 	width: 100%;
	// 	height: 100%;
	// 	content: counter(boxes);
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	top: 0;
	// 	left: 0;
	// }
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.blue {
	background: #4040e7;
	border: 5px solid rgba(0, 0, 0, 0.5);
}

.red {
	background: #db3c3c;
	border: 5px solid rgba(0, 0, 0, 0.5);
}

.green {
	background: #3ddb3d;
	border: 5px solid rgba(0, 0, 0, 0.5);
}

.tall {
	grid-column: span 2;
	grid-row: span 2;
}

.wide {
	grid-row: span 1;
	grid-column: span 1;
}

.square {
	grid-row: span 1;
	grid-column: span 1;
}

.shuffleWrap {
	padding: 20px;
	display: flex;
	justify-content: center;
}

@media (max-width: 500px) {
	.grid {
		grid-template-columns: 1fr;
	}
	.wide {
		grid-column: span 1;
	}
}
