$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.5);
$font-size-lg:                ($font-size-base * 1.25);
$font-size-sm:                ($font-size-base * .875);
$font-size-xs:                ($font-size-base * .75);

$black:    #000 !default;
$gray-500: #adb5bd !default;
$gray-aaa: #AAAAAA !default;
$gray-170: rgb(170, 170, 170) !default;
$gray-216: rgb(216, 216, 216) !default;

$blue:    rgb(55,132,196) !default;
$fca-green: #00ab84 !default;
$fca-blue: rgb(55,132,196) !default;
$white:    #ffffff !default;


/* In your CSS */
.article-author-initial-div {
    display: flex;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    background-color: $blue;
}
.article-author-initial-text {
    font-size: 14px;
    font-weight: 700;
    color: $white;
}
.article-author-image {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    overflow: hidden;
}
.article-author-name {
    font-size: 14px;
    color: $black;
    font-weight: 700;
}
.article-author-date {
    font-size: 12px;
    color: $gray-170;
}
.article-title {
    font-size: 16px;
    font-weight: 700;
    color: $black;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

// Like Button
//App.css
.like-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
 }
 .like-button {
    border: none; 
    outline: none;
    background-color: transparent;
    font-size: 14px;
 }
 .like-button-icon {
    color: $gray-216;
    font-size: 24px;
    margin-right: 5px;
 }
 .like-button-icon-liked {
    color: $blue;
 }
 .like-button-title {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    margin-right: 5px;
 }
 .like-button-number {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
 }

.article-image {
    height: auto;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

// comment button
.comment-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
 }
.comment-button {
    border: none; 
    outline: none;
    background-color: transparent;
    font-size: 14px;
 }
 .comment-button-icon {
    color: $gray-216;
    font-size: 24px;
    margin-right: 5px;
 }
 .comment-button-title {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    margin-right: 5px;
 }
 .comment-button-number {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
 }

 // share button
 .share-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
 }
 .share-button {
    border: none; 
    outline: none;
    background-color: transparent;
    font-size: 14px;
 }
 .share-button-icon {
    color: $gray-216;
    font-size: 24px;
    margin-right: 5px;
 }
 .share-button-title {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    font-weight: 600;
    margin-right: 5px;
 }
 .share-button-number {
    color: $black;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
 }

 .like-main-button-container {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
 }
 .share-main-button-container {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
 }
 .comment-main-button-container {
    display: flex;
    flex: 1;
    align-self: center;
    justify-content: center;
 }

 // single
 @media (max-width: 550px) {
    .like-main-button-container {
        justify-content: flex-start;
     }
     .share-main-button-container {
        justify-content: flex-end;
     }
    .like-button-icon {
        font-size: 18px;
    }
    .comment-button-icon {
        font-size: 18px;
    }
    .share-button-icon {
        font-size: 18px;
    }
    .like-button-title {
        font-size: 12px;
    }
    .comment-button-title {
        font-size: 12px;
    }
    .share-button-title {
        font-size: 12px;
    }
}

// Details
.article-details-author-image {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    overflow: hidden;
}
.article-details-author-initial-div {
    display: flex;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    background-color: $blue;
}
.article-details-author-initial-text {
    font-size: 16px;
    font-weight: 700;
    color: $white;
}
.article-details-author-name {
    font-size: 16px;
    color: $black;
    font-weight: 700;
}
.article-details-author-date {
    font-size: 14px;
    color: $gray-170;
}
.article-details-title {
    font-size: 18px;
    font-weight: 700;
    color: $black;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.article-details-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit:cover;
    object-position: center;
}
.article-content {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
}
.article-category-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
}

.article-comment-submit-btn {
    font-weight: bold;
    padding:  $pd15;
    font-size: $fs15;
    line-height: 1.2;
    border-radius: 0;
    min-width: $pd200;
    cursor:pointer;

    &.article-comment-btn-gradient{
        display: flex;
        padding:  14px $pd45;
        justify-content: center;
        align-items: center;
        background-color: rgb(81,168,134) !important;
        background-image: linear-gradient(90deg, $fca-green 0%, $fca-blue 50%) !important;
        background-position: left center;
        background-size: 150% 100%;
        color: #ffffff !important;
        font-weight: 600;
        border: 0;
        transition: all .3s ease;
        &:hover{
            background-position: right center;
        }
    }
    &.article-comment-btn-default{
        display: flex;
        justify-content: center;
        align-items: center;
        padding:  $pd15 $pd45;
        background-color: #EEEEEE;
        box-shadow: none;
        color: black;
    }
}

.is-button-disabled {
    pointer-events: none;
    opacity: 0.7;
}