@import '../../../assets/scss/custom/variables';

.root {
  background-color: white;
  padding: 30px;
  margin-bottom: 20px;
}
.text_dark {
  color: $fca-dark;
}
.text_color_green {
  color: #00AB84;
}
.gradient_box_active {
  border: 3px solid rgb(0, 133, 202);
  border-radius: 1em;
  padding: 1em 0.5em;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gradient_box_active2 {
  border: 3px solid rgb(0, 133, 202);
  border-radius: 1em;
  padding: 1em 0.5em;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gradient_box_inactive {
  border: 3px solid #EEEEEE;
  border-radius: 1em;
  padding: 1em 0.5em;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.font1_active {
  font-size: $fs65;
  font-weight: 700;
  color: rgba(232,89,65);
}
.font1_active2 {
  font-size: $fs65;
  font-weight: 700;
  color: #CF4D86;
}
.font2_active {
  font-size: $fs24;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
}
.color_light_green {
  color: rgb(0, 133, 202);
}
.font3_active {
  font-size: $fs24;
  font-weight: 700;
  text-transform: uppercase;
}
.font1_inactive {
  font-size: $fs65;
  font-weight: 700;
  color: #EEEEEE;
}
.font2_inactive {
  font-size: $fs24;
  font-weight: 700;
  line-height: 1em;
  text-transform: uppercase;
  color: #EEEEEE;
}
.font3_inactive {
  font-size: $fs24;
  font-weight: 700;
  text-transform: uppercase;
  color: #EEEEEE;
}
.color_light_grey {
  color: #EEEEEE;
}
.coupon_row_1 {
  margin-top: 1rem;
  padding: 1.5rem 0.5rem;
  padding-right: 0;
  background-color: #8C479910;
  border-left: 5px solid #8C4799;
  color: #8C4799;
}
.coupon_input_button_container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background-color: white;
  width: 70%;
}
.coupon_input {
  flex: 8;
  height: 2.5rem;
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  color: #101820;
  overflow: hidden;
}
.coupon_input::placeholder {
  color: #BBBBBB; /* Placeholder text color */
}
.coupon_button {
  flex: 2;
  background-color: transparent;
  border: none;
  color: #8C4799;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 15px;
  height: 2.5rem;
  transition: background-color 0.3s ease; /* Add transition for a smooth animation */
  display: flex;
  align-items: center;
  justify-content: center;
}
.coupon_button:hover {
  background-color: #8C4799; /* Change the background color when clicked */
  color: #ffffff; /* Change the text color when clicked */
}
.coupon_button:active {
  background-color: #8C4799; /* Change the background color when clicked */
  color: #ffffff; /* Change the text color when clicked */
}
.coupon_button_remove {
  flex: 2;
  background-color: rgb(155, 2, 2);
  border: none;
  color: #fff;
  font-family: "Poppins",sans-serif;
  font-weight: 600;
  font-size: 15px;
  height: 2.5rem;
  transition: background-color 0.3s ease; /* Add transition for a smooth animation */
  // display: flex;
  display: none;
  align-items: center;
  justify-content: center;
}
.coupon_button_remove:hover {
  background-color: rgb(188, 52, 52);/* Change the background color when clicked */
  color: #ffffff; /* Change the text color when clicked */
}
.sf_subsidy_row_1 {
  margin-top: 1rem;
  padding: 1.5rem 0.5rem;
  padding-right: 0;
  background-color: rgba(232, 89, 65, 0.1);
  border-left: 5px solid #E85941;
  color: #E85941;
}
.sf_subsidy_row_2 {
  margin-top: 1rem;
  padding: 1.5rem 0.5rem;
  padding-right: 0;
  background-color: rgba(207, 77, 134, 0.1);
  border-left: 5px solid #CF4D86;
  color: #CF4D86;
}
.sfc_eligible_row {
  margin-top: 1rem;
  padding: 1.5rem 0.5rem;
  padding-right: 0;
  background-color: rgba(0, 171, 132, 0.1);
  border-left: 5px solid #00AB84;
}
.green_hyperlink {
  color: #00AB84;
  text-decoration: underline;
}
.no_thanks_txt {
  text-decoration: underline;
  color: #999999;
  font-size: $fs12;
  cursor: pointer;
}
.sfc_btn_wrap{
  max-width: dynamic_px(160,200);
}
.btn_disabled{
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
.sfc_eligible_txt {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-left: 5px;
  color: rgb(0, 133, 202);

}
.sfc_eligible_utilised_txt {
  background-color: rgb(0, 133, 202);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgb(0, 133, 202);
  margin-left: 5px;
  svg path {
    fill: #fff;
  }
}
.info_txt, .info_txt_hover {
  background-color: rgb(0, 133, 202)1A;
  color: rgb(0, 133, 202);
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 11px;
  font-weight: 500;
}
.info_txt_hover {
  position: absolute;
  left: 100%;
  top: 10px;
  z-index: 9;
}

.box_active1 {
  position: relative;
  svg {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202));
    animation: svg-shadow1 4s ease-in-out infinite alternate-reverse;
  }
}
.box_active2 {
  position: relative;
  svg {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202));
    animation: svg-shadow2 4s ease-in-out infinite alternate-reverse;
  }
}
.box_active3 {
  position: relative;
  svg {
    filter: drop-shadow( 0 0 5px #52d3c6);
    animation: svg-shadow3 4s ease-in-out infinite alternate-reverse;
  }
}
.box_active4 {
  position: relative;
  svg {
    filter: drop-shadow( 0 0 5px #00ab84);
    animation: svg-shadow4 4s ease-in-out infinite alternate-reverse;
  }
}


@keyframes svg-shadow1 {
  25% {
    filter: drop-shadow( 0 0 5px #cf4d86);
  }
  50% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202));
  }
  75% {
    filter: drop-shadow( 0 0 5px #cf4d86);
  }
  100% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202));
  }
}
@keyframes svg-shadow2 {
  25% {
    filter: drop-shadow( 0 0 5px #e85941) 
  }
  50% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202));
  }
  75% {
    filter: drop-shadow( 0 0 5px #e85941) 
  }
  100% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202))
  }
}
@keyframes svg-shadow3 {
  25% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202)) 
  }
  50% {
    filter: drop-shadow( 0 0 5px #52d3c6);
  }
  75% {
    filter: drop-shadow( 0 0 5px rgb(0, 133, 202)) 
  }
  100% {
    filter: drop-shadow( 0 0 5px #52d3c6)
  }
}
@keyframes svg-shadow4 {
  25% {
    filter: drop-shadow( 0 0 5px #101820) 
  }
  50% {
    filter: drop-shadow( 0 0 5px #00ab84);
  }
  75% {
    filter: drop-shadow( 0 0 5px #101820) 
  }
  100% {
    filter: drop-shadow( 0 0 5px #00ab84)
  }
}

.box_inactive {
  position: relative;
  svg {
    linearGradient stop, text {
      fill: #EEEEEE !important;
      color: #EEEEEE !important;
      stop-color: #EEEEEE !important;
    }
  }
}

.info_icon_container {
  height: 16px;
  width: 16px;
  position: absolute;
  bottom: 15px;
  right: 30px;
}

// responsive
@media (max-width: 1318px) {
  .info_icon_container {
    bottom: 10px;
    right: 20px;
  }
  .coupon_input_button_container {
    width: 80%;
  }
}

@media (max-width: 991px) {
  .info_icon_container {
    height: 16px;
    width: 16px;
    bottom: 10px;
    right: 30px;
  }
  .coupon_input_button_container {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .info_icon_container {
    height: 16px;
    width: 16px;
    bottom: 15px;
    right: 30px;
  }
}

@media (max-width: 498px) {
  .coupon_number_text {
    width: 100%;
    justify-content: flex-end;
  }
  .coupon_input_button_container {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .info_icon_container {
    height: 14px;
    width: 14px;
    bottom: 10px;
    right: 25px;
  }
}
.declaration_labels {
  color: #101820; 
  font-family: "Poppins", sans-serif;
  font-size: calc(13px + (16 - 13) * (100vw - 375px) / (1900 - 375));
  font-weight: normal;
  line-height: 1.4;
}
