.form-section .react-tabs__tab {
    background-color: #ddd;
    color: #0C2236;
    padding: 14px;
    font-family: Coolvetica;
    font-size: 18px;
    font-weight: bold;
}

.contact_form_tab .react-tabs__tab.react-tabs__tab--selected {
    background-color: #fff;
    color: #00ab84;
    border: 2px solid #00ab84;
    border-radius: 0px;
}

.contact_form_tab {
    display: flex;
    padding-left: 0;
}

.form-section {
    background-color: #F7F7F7;
    padding-top: 1em;
}

.contact_header {
    font-weight: bold;
    font-size: 26px;
    font-family: 'Coolvetica';
    text-align: center;
    padding: 0 2em;
}

.contact_header_colored {
    color: #00ab84;
}

.contact_banner {
    display: flex;
    justify-content: center;
}

.contact_banner img {
    width: 100%;
}

#individual-contact-form {
    padding-left: 2em;
    padding-right: 2em;
}

.form_content,
.react-tabs__tab-list,
.form_content .card {
    background-color: #F7F7F7;
}

.form-section .react-tabs__tab-list {
    padding: 0 2em;
    border-bottom: 0;
}

.form-control-label span {
    font-weight: normal;
}

.contact-form-close .close.close-black {
    display: none;
}

.contact-form-close {
    padding: 0;
    background-color: #F7F7F7;
}

.contact-form-close .close {
    position: absolute;
    right: -50px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.consent-label a {
    color: #00ab84
}

.consent-label a:hover {
    color: #233dd2;
}

@media (max-width: 1280px) {
    .contact-form-close .close.close-black {
        display: block;
    }
    .contact-form-close .close.close-white {
        display: none;
    }
    .contact-form-close .close {
        right: 20px;
    }
}

@media (max-width: 768px) {
    .react-tabs__tab-list {
        padding: 0;
    }
    .contact-form-close .close {
        right: 20px;
    }
    .contact_header {
        padding: 0;
    }
    .contact_banner {
        display: none;
    }
    .contact-form-close {
        background-color: #F7F7F7;
    }
    .form_content .contact-submit {
        justify-content: center !important;
        padding-right: 0 !important;
    }
}