@import '../../../../assets/scss/custom/variables';


.review_container {
    display: flex;
    flex-direction: column;
    padding: 80px;
    padding-top: 60px;
    padding-bottom: 20px;
    background-image: linear-gradient(90deg, $fca-green-light, $fca-blue-light) !important;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
  
.visible {
    opacity: 1 !important;
}

.review_image_title {
    font-weight:600;
    color: #001932;
    font-size: $fs48;
    text-align: center;
    margin-bottom: 5px;
}
.review_image_title_blue {
    color: $fca-blue;
}
.review_image_title_green {
    color: $fca-green;
}
.review_google_title_1 {
    color: rgb(94, 133, 229);
}
.review_google_title_2 {
    color: rgb(198, 89, 72);
}
.review_google_title_3 {
    color: rgb(232, 192, 90);
}
.review_google_title_4 {
    color: rgb(94, 133, 229);
}
.review_google_title_5 {
    color: rgb(110, 163, 100);
}
.review_google_title_6 {
    color: rgb(198, 89, 72);
}
.review_image_desc_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
}
.review_image_desc {
    font-size: $fs24;
    font-weight: 600;
    text-align: center;
}
.review_image_desc2 {
    font-size: $fs18;
    text-align: center;
}
.review_image_star {
    width: 22px;
    height: 22px;
    margin-left: 4px;
    margin-right: 4px;
}

.review_image_slick {
    margin-top: 2rem;
    margin-bottom: 6rem;
    :global .slick-track {
      display: flex ;
      gap: 0.4rem ;
    }
}
.review_image_img {
    width: auto;
    height: auto;
    object-fit: contain;
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}

.review_slick {
    margin-top: 1rem;
    margin-bottom: 3rem;
    :global .slick-track {
      display: flex ;
      gap: 0.4rem ;
    }
}
.review_card_container {
    padding: 1rem;
    padding: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: white;
    border: 1px solid  #FBBC05;

    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}
.review_box{
    padding: 0 $pd5;
    height: 100%;
   >div{
    height: 100%;
    padding: $pd30;
    padding-top: $pd35;
    padding-bottom: $pd35;
    border: 1px solid $fca-yellow;
    background-color: white;
   }
   .review_title{
    font-size: $fs22;
    color: #0C2236;
   }
}

.review_user_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 14px;
}
.review_user_img {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    object-fit: cover;
    border: 0.5px solid rgb(230, 230, 230);
    margin-right: 1rem;
}
.review_username {
    font-weight:600;
    color: #0C2236;
    font-size: $fs22;
}
.review_star_container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
}
.review_star {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}
.review_description {
    color: #0C2236;
    font-size: $fs18;
}

// review image
.gallery_container {
    margin-bottom: 4rem;
}
.gallery_grid_wrapper {
    display: grid;
    grid-template-columns: calc(25% - 15px) calc(25% - 15px) calc(25% - 15px) calc(25% - 15px);
    grid-template-rows: auto;
    gap: 20px;
    margin-bottom: -10%;
}
.gallery_grid_wrapper .gallery_box {
    overflow: hidden;
}
.gallery_grid_wrapper .box_5 {
    grid-column: 4;
    grid-row: 2;
    transform: translateY(-20%);
    z-index: 1;
}
.gallery_grid_wrapper .box_6 {
    grid-column: 4;
    grid-row: 3;
    transform: translateY(-25%);
}
.gallery_grid_wrapper .box_7 {
    grid-column: 3;
    grid-row: 3;
    transform: translateY(-49%);
}
.gallery_grid_wrapper .box_8 {
    grid-column: 2;
    grid-row: 3;
    transform: translateY(-49%);
}
.gallery_grid_wrapper .box_9 {
    grid-column: 1;
    grid-row: 3;
    transform: translateY(-25%);
}
.gallery_grid_wrapper .box_10 {
    grid-column: 1;
    grid-row: 2;
    transform: translateY(-20%);
    z-index: 1;
}
.gallery_grid_wrapper .box_5, .gallery_grid_wrapper .box_10 {
    position: relative;
}

.gradient_border {
    border-width: 3px;
    border-style: solid;
    -o-border-image: linear-gradient(to right,rgb(0,171,132),rgb(0,133,202)) 1;
    border-image: linear-gradient(to right,rgb(0,171,132),rgb(0,133,202)) 1;
}
.gallery_grid_wrapper .text_box {
    padding: calc(((20 / 1.5) * 1px) + (20 - (20 / 1.5)) * (100vw - 375px) / (1900 - 375));
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 2/4;
    grid-row: 2;
    height: 60%;
    background: #fff;
    align-items: center;
    /* transform: translateY(-20%); */
}

.text_center {
    text-align: center;
}

.m_b_20, .m_y_20 {
    margin-bottom: calc(13.3333333333px + 6.6666666667 * (100vw - 375px)/ 1525);
}

.galleryImage {
    height: auto;
    max-width: 100%;
}
.aos_init {

}
.aos_animate {

}
.lazyloaded {

}

.gallery_grid_wrapper .gallery_box:not(.text_box) img {
    width: 100%;
}

.gallery_grid_wrapper .box_5:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0%;
    top: 0%;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #f78146;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: 0 0, 100% 100%;
    background-image: linear-gradient(#f78146,#f78146), linear-gradient(#fff, #fff);
    -webkit-animation: counter_rotation 4s linear infinite !important;
    animation: counter_rotation 4s linear infinite !important;
}
.gallery_grid_wrapper .box_10:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    /* left: -2.5%; */
    /* top: -2.5%; */
    /* width: 105%; */
    /* height: 105%; */
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #35ac86;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    background-position: 0 0, 100% 100%;
    background-image: linear-gradient(#35ac86, #35ac86), linear-gradient(#fff, #fff);
    -webkit-animation: rotation 4s linear infinite;
    animation: rotation 4s linear infinite;
}
@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
}
@keyframes counter_rotation {
    from {
      transform: rotate(359deg);
    }
    to {
      transform: rotate(0deg);
    }
}
.gallery_grid_wrapper .gallery_box:not(.text_box).box_10 img, .gallery_grid_wrapper .gallery_box:not(.text_box).box_5 img {
    padding: 5px;
}

// responsive
@media (max-width: 1318px) {
    .review_image_title {
        font-size: $fs44;
    }
    .review_image_desc {
        font-size: $fs22;
        text-align: center;
    }
    .review_image_desc2 {
        font-size: $fs18;
        text-align: center;
    }
    .review_image_star {
        width: 20px;
        height: 20px;
    }
    .review_username {
        font-size: $fs20;
    }
    .review_description {
        font-size: $fs16;
    }
}

@media (max-width: 991px) {
    .review_container {
        padding: 60px;
        padding-top: 60px;
        padding-bottom: 20px;
    }
    .review_image_title {
        font-size: $fs40;
    }
    .review_image_desc {
        font-size: $fs20;
        text-align: center;
    }
    .review_image_desc2 {
        font-size: $fs16;
        text-align: center;
    }
    .review_image_star {
        width: 18px;
        height: 18px;
    }
    .review_description {
        font-size: $fs14;
    }
    .gallery_grid_wrapper {
        grid-template-columns: calc(50% - 10px) calc(50% - 10px);
        grid-template-rows: auto;
        margin-bottom: 20px;
    }

    .gallery_grid_wrapper .box_1 {
        grid-column: 1;
        grid-row: 2;
        transform: none;
    }
    .gallery_grid_wrapper .box_2 {
        grid-column: 2;
        grid-row: 3;
        transform: none;
    }
    .gallery_grid_wrapper .box_3 {
        grid-column: 1;
        grid-row: 3;
        transform: none;
    }
    .gallery_grid_wrapper .box_4 {
        grid-column: 2;
        grid-row: 2;
        transform: none;
    }
    .gallery_grid_wrapper .box_5 {
        grid-column: 1;
        grid-row: 4;
        transform: none;
    }
    .gallery_grid_wrapper .box_6 {
        grid-column: 2;
        grid-row: 6;
        transform: none;
    }
    .gallery_grid_wrapper .box_7 {
        grid-column: 1;
        grid-row: 5;
        transform: none;
    }
    .gallery_grid_wrapper .box_8 {
        grid-column: 2;
        grid-row: 5;
        transform: none;
    }
    .gallery_grid_wrapper .box_9 {
        grid-column: 1;
        grid-row: 6;
        transform: none;
    }
    .gallery_grid_wrapper .box_10 {
        grid-column: 2;
        grid-row: 4;
        transform: none;
    }
    .gallery_grid_wrapper .text_box {
        grid-column: 1/3;
        grid-row: 1;
        height: auto;
    }
    .review_username {
        font-size: $fs18;
    }
}

@media (max-width: 767px) {
    .review_container {
        padding: 20px;
    }
    .review_image_title {
        font-size: $fs36;
    }
    .review_image_desc {
        font-size: $fs18;
        text-align: center;
    }
    .review_image_desc2 {
        font-size: $fs14;
        text-align: center;
    }
    .review_image_star {
        width: 16px;
        height: 16px;
    }
    .review_description {
        font-size: $fs12;
    }
    .review_username {
        font-size: $fs16;
    }
}

