@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "../../../../assets/scss/custom/functions";
@import "../../../../assets/scss/custom/variables";

.container {
  background-color: #F5FAFE;
  // padding-bottom: 200px;
  padding-bottom: $pd50;
}

.course_title { 
  font-size: x-large;
  margin-top: $pd15;
}

.content_block {
  background-color: white;
  padding: $pd30;
  width: 100%;
}

.content_sticky{
  position: sticky;
  top: 10%;
  z-index: 1;
  max-height: 85vh;
  overflow-y: auto;
}

.course_badge {
  font-size: x-small;
  margin-bottom: 0.5rem;
  white-space: normal;
  font-weight: normal;
  text-align: start;
  text-transform: capitalize;
}


.media_block {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover ;
}

.media_slick {
  padding-top: 2rem;

  :global .slick-track {
    display: flex ;
    gap: 0.2rem ;
  }
}

.benefit_box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 10px;
}

.benefit_icon {
  width: 50px;
  height: 50px;
}

.heading {
  font-size: x-large;
  margin-bottom: 5px;
}

.benefit_title {
  font-size: large;
  margin-bottom: 5px;
}

.benefit_description {
  margin-top: 0;
}

.benefit_illustration{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.benefit_cert_img{
  // height: 350px;
  max-width: 100%;
  display: block;
  margin: auto;

}

.course_fee_wrap{

  .nav_header{
    border-bottom: none;
    .nav_button{
      width: calc(100% / 3);
      >div{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $pd15;
        background-color: #DDDDDD;
        border: 1px solid #CCCCCC;
        cursor: pointer;
        position: relative;
        &.active{
          background-color: #F7F7F7;
          border-color: #F7F7F7;
          &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: $pd5;
            background: rgb(0,171,132);
            background: linear-gradient(90deg, rgba(0,171,132,1) 0%, rgba(0,133,202,1) 100%);
          }
        }
        >div{
          b{
            display: block;
            font-family: $font_2;
            font-size: $fs20;
          }
          span{
            display: block;
            padding-top: 5px;
          }
        }
      }
    }
  }
  
  .content_wrap{
    background-color: #F7F7F7;
    padding: $pd30;
    table{
      background-color: #ffffff;
      thead{
        td{
          padding: $pd10;
          font-weight: bold;
        }

      }
      td{
        padding: $pd20;
        border: 1px solid #CCCCCC;
        a{
          color: $fca-green;
          text-decoration: underline;
        }
      }
    }

    .note_wrap{
      font-size: $fs12;

      ul{
        padding-left: $pd20;
        li{
          color: $fca-orange;
          span{
            color: $body-color;
          }
        }
      }
    }
  }
}

.trainer_slider_nav{
  .trainer_box{
    font-size: $fs15;
    cursor: pointer;
    padding: 0 $pd20;
    img{
      display: block;
      object-fit: contain;
      border-radius: 50%;
      background: #eeeeee;
      aspect-ratio: 1/1;
    }
    .name{
      margin-bottom: 0;
      font-weight: 500;
    }
    .qualification{
      color: #AAAAAA;
    }
  }
}

.trainer_slider{
  .trainer_box{
    img{
      object-fit: cover;
      aspect-ratio: 1/1;
    }

  }
}

.review_box{
  padding: 0 $pd10;
  height: 100%;
 >div{
  height: 100%;
  padding: $pd20;
  border: 1px solid $fca-yellow;
 }
 .review_title{
  font-size: $fs22;
  color: #0C2236;
 }
}

.course_slick {
  margin-top: 2rem;
  margin-bottom: 2rem;
  
  :global .slick-track {
    display: flex ;
    gap: 0.4rem ;
  }
}

.course_card {
  flex-basis: 300px;
  flex-shrink: 0;
  margin-right: 2rem;
  cursor: pointer;
  // margin-left: -1rem;
}

.course_badge {
  font-size: x-small;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: normal;
  text-align: start;
  text-transform: capitalize;
}

.course_title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  min-height: 2rem;
}

.course_banner_image {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.enquiry,
.callme {
    background-color: white;
    padding: 20px;
    position: sticky;
}

.call_us_btn {
  width: 100%;
  border: 1px solid #4BA58C;
  color: #00AB84;
  background-color: #e5f6f2;
}





@media (max-width: 1199px) {
  .cert_section_1{
    .benefit_illustration{
      order: 1;
    }
    .benefit_content{
      order: 2;
    }
  }

    
  .course_fee_wrap{
    .nav_header{
      .nav_button{
        >div{
          >div{
            b{
            }
            span{
              font-size: $fs14;
            }
          }
        }
      }
    }

  }


}

@media (max-width: 575px) {

  .course_fee_wrap{
    .nav_header{
      .nav_button{
        >div{
          >div{
            b{
              font-size: $fs14;
            }
            span{
              font-size: $fs12;
            }
          }
        }
      }
    }

  }

    
  .trainer_slider_nav{
    .trainer_box{
      text-align: center;
      img{
      }
      .name{
        padding-top: $pd20;
      }
      .qualification{
      }
    }
  }


}