.login_modal_header {
  font-size: xx-large;
}

.login_modal_col {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  padding: 40px;
}

.login_modal_img {
  object-fit: cover;
  width: 100%;
}



.hightlight-text {
  color: #00AB84;
}