@media (max-width: 1399px) {
    .reschedule_main_container {
        padding-left: 18rem;
        padding-right: 18rem;
    }
}
@media (max-width: 1199px) {
    .main-container{
       .row{
            >.content-wrap{
                flex: 0 0 100%;
                max-width: 100%;
            }
            >.profile-wrap{
                display: none;
            }
       }
    }

    .meal-selection-tab-row{
        .w-20{
            width: calc(100% / 3) !important;
        }
        .w-80{
            width: calc((100% / 3) * 2) !important;
        }
    }

    .day-wrap{
        flex-wrap: wrap;
        .tea-break-wrap{
            width: 50% !important;
            order: 2;
        }
        .lunch-wrap{            
            width: 100% !important;
            order: 1;
            margin-bottom: 2rem;
        }
    }
    .meal-selection-tab-content-wrap .day-wrap .tea-break-wrap .content .title-wrap{
        align-items: center;
        .text-container h5{
            max-width: unset;
            margin: 0;
        }
        
    }

    .course-outline-wrap{ 
        .content-row{
            .content-wrap{
                .content-item{
                    padding: dynamic_px(10,30);
                    .desc{
                        padding-top: $pd15;
                        table{
                            td{
                                padding: $pd5;
                                font-size: $fs12;
                            }
                        }
                    }
                }
            }
        }
    }

    .course-training-location-container{
        font-size: $fs14;
    }


    .my_course_dashboard_container {
        margin-left: 3rem;
        margin-right: 3rem;
    }

    .upcoming-course-info-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .bundle-info-container {
        padding-left: 3rem;
        padding-right: 3rem;
    }
    .reschedule_main_container {
        padding-left: 14rem;
        padding-right: 14rem;
    }
}

@media (max-width: 991px) {
    // .featured_event_slider .slick-slider {
    //     display: flex;
    // }
    // .featured_event_slider .slick-slider .slick-list {
    //     display: flex;
    // }
    // .featured_event_slider .slick-slider .slick-list .slick-track {
    //     display: flex;
    // }
    // .featured_event_slider .slick-slider .slick-list .slick-track .slick-slide {
    //     display: flex;
    // }
    // .featured_event_slider .slick-slider .slick-list .slick-track .slick-slide > div {
    //     display: flex;
    //     flex: 1;
    //     padding-bottom: 10px;
    // }
}

@media (max-width: 980px) {
    .navbar-vertical.navbar-expand-xs .navbar-collapse {
        display: flex;
        overflow: hidden;
        flex: 1 1;
        flex-direction: column;
        align-items: stretch;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }
    .main-content > .main-container{
        // padding-bottom: dynamic_px(80,100) !important;
    }

    .navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
        // padding: 0.675rem 1.5rem;
        padding: 0;
        margin-top: 1rem;
        // padding-top: 1rem;
        padding-bottom: 1rem;
        // padding-top: 0.675rem;
        // padding-bottom: 0.675rem;
    }

    .navbar-vertical.navbar-expand-xs .navbar-nav > .nav-item > .nav-link.active {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        padding-left: 0;
        padding-right: 0;
        border-radius: 0.375rem;
    }

    .navbar-vertical.navbar-expand-xs .navbar-nav {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-nav {
        background-color: $fca-blue;
    }


    .navbar-vertical.navbar-expand-xs .navbar-inner {
        display: flex;
        padding-left: 0;
        padding-right: 0;
    }

    .navbar-vertical.navbar-expand-xs.fixed-left{
        padding: 0 !important;
        max-width: unset !important;
        top: auto;

        .sidenav-header{
            display: none !important;
        }
        .navbar-nav{
            flex-direction: row;
            .nav-item{
                flex: 1;
                margin-top: 0;
                text-align: center;
                padding: 0;
                .nav-link{
                    display: block;
                    i{
    
                    }
                    .nav-link-text{
                        display: block;
                    }
                }
            }
   
        }

    }
    .navbar-vertical .navbar-collapse:before{
        display: none;
    }

    .btn{
        width: 100%;
        display: block;
        padding: $pd20;
        margin: 0 !important;

    }

    .hide-980{
        display: none !important;
    }

    .profile-edit{
        .btn-container{
            .btn-default{
                order: 2;
                margin-top: $pd30 !important;
            }
            .btn-gradient{
            margin-top: 0 !important;
            }
        }
    }
    
    .profile-user-wrap{
        padding-bottom: $pd20;
    }

    .navbar-vertical .navbar-nav .nav-item{
        .nav-link{
            font-size: $fs14;
            color: white;
            font-weight: 300;
            > i{
                padding-right: 0;
            }
        }
        &.active{
            a,i{
                color: white;
            }
        }
    }

    .course-right-container{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: translateY(111%);
        transition: all .3s ease;
        z-index: 999;
        background-color: #fff;
        padding: 60px 0;
        &.active{
            transform: translateY(0);            
        }
        .content-sticky{
            position: relative;
            max-height: 100%;
            top: 0;
            overflow-y: auto;
            .course-title{
                font-size: $fs22;
            }
            >div{
                padding: 0;
                width: 90%;
                margin: auto;
            }
        }

        .mobile-back-button{
            position: absolute;
            top: 20px;
            left: 5%;
        }
    }
    .upcoming_course{
        .course_detail label, .course_detail p, .course-session{
            font-size: $fs14;
        }
    }

    .my_course_dashboard_container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .upcoming-course-info-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bundle-info-container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .reschedule_main_container {
        padding-left: 6rem;
        padding-right: 6rem;
    }
}

@media (max-width: 767px) {
    .profile-user-wrap{
        padding-bottom: $pd20;
    }

    .course-inner-info{
        .course_detail.row{
          .p-2{
            .p-2{
              padding-top: 0 !important;
              padding-bottom: 0 !important;
            }
          }
        }
    }
    .my_course_dashboard_container {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .upcoming_course_card_container {
        display: flex;
        flex-direction: column;
      }
      .upcoming_course_card_data_container {
        flex: 1;
        flex-direction: column;
        padding: $fs30;
      }
      .upcoming_course_card_banner_container {
        flex: 1;
        order: -1;
      }

      .upcoming-course-info-container {
        padding-left: 2rem;
        padding-right: 2rem;
      }
      .bundle-info-container {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    .reschedule_main_container {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .bundle_card_container {
        display: flex;
        flex-direction: column;
    }
    .bundle_card_data_container {
        flex: 1;
        flex-direction: column;
        padding: $fs30;
    }
    .bundle_card_banner_container {
        flex: 1;
        order: -1;
      }
}

@media (max-width: 575px) {
    .register-page{
        .btn-container{
            display: block !important;

            .signup_wrap{
                margin-left: 0 !important;
                margin-top: $pd15;
            }
        }
    }

    .cta-wrap{
        .col{
            flex: 0 0 100%;
            max-width: 100%;
            margin: $pd20 0;
        }

        .card{
            .card-body{
                display: block;
                >*{
                    padding: $pd30;
                    display: flex;
                    align-items: center;
                    position: relative;
                    &::before{
                        position: absolute;
                        top: 50%;
                        right: $pd30;
                        transform: translateY(-50%);
                        content: "\f105";
                        font-family: "Font Awesome 5 Free";
                        -moz-osx-font-smoothing: grayscale;
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        -webkit-font-feature-settings: normal;
                        font-feature-settings: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        font-weight: 900;
                        font-size: $fs20;
                    }
    
                    .text-container{
                        padding-top: 0 !important;
                        padding-left: $pd15;
                    }
                }
            }
        }


        
    }

    .shuttle-location-wrap{
        .col{
            flex: 0 0 100%;
            max-width: 100%;
            margin: $pd15 0;
        }
    }

    .meal-selection-tab-row{
        padding-bottom: 2rem !important;
        .w-20{
            width: 100% !important;
        }
        .w-80{
            width: 100% !important;
        }
    }

    .day-wrap{
        flex-wrap: wrap;
        .tea-break-wrap{
            width: 100% !important;
            order: unset;
            margin-bottom: 2rem;
        }
        .lunch-wrap{            
            order: unset;
            margin-bottom: 2rem;
            .option-wrap{
                flex-wrap: wrap;
            }
        }
    }
    
    .meal-selection-tab-content-wrap .day-wrap .tea-break-wrap .content .title-wrap{
        align-items: center;
        .text-container h5{
            max-width: unset;
            margin: 0;
        }
        
    }

    .meal-selection-tab-content-wrap .day-wrap .lunch-wrap .option-wrap label {
        flex: auto;
        width: 100%;
        padding-bottom: .5rem;
    }

    .profile-wrap .profile-details {
        padding-top: $pd10;
        padding-bottom: $pd10;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .profile-wrap .profile-details + .profile-details {
        border-left: none;
    }

    .navbar-vertical .navbar-nav .nav-item .nav-link {
        font-size: $fs12;
        color: white;
        font-weight: 300;
    }

    .cert_modal{
        .pdf-container{
          canvas{
            height: 100% !important;
            width: 100% !important;
            margin: auto;
          }
        }
    }

    .course-training-location-container{
        .content-wrap{
            .tab-content-wrap{
                .course-run-item-wrap{
                    .course-run-card{
                        .date-display{
                            width: dynamic_px(55,86);
                            height: dynamic_px(60,96);
                            .date-display-inner{
                                .day{
                                    font-weight: 600;
                                    font-size: dynamic_px(30,46);
                                    line-height: 1;
                                }
                            }
                        }
                        .hide-right-border-540{
                            border-right: none;
                        }
                        .top-border-540{
                            border-top: 1px solid rgba(0, 171, 132, 0.3);
                            margin-top: $pd20;
                        }
                        .content{
                            padding-left: 0;
                            padding-right: 0;
                            font-size: $fs12;
                        }
                    }
                }
            }
        }
    }

    .my_course_dashboard_container {
        margin-left: 0;
        margin-right: 0;
    }

    .upcoming_course{
        .course_detail label, .course_detail p, .course-session{
            font-size: $fs12;
        }
    }

    .upcoming-course-info-container {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .bundle-info-container {
        padding-left: 0rem;
        padding-right: 0rem;
    }
    .reschedule_main_container {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .learner-detail-div{
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
      
   
}

@media (min-width: 768px) and (max-width: 980px) {
    .register-page{
        .col-lg-6{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

}

@media (min-width: 981px) and (max-width: 1199px) {
    .register-page{
        .col-lg-6{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .g-sidenav-pinned {
        .sidenav {
            &.fixed-left + .main-content {
                margin-left: $navbar-vertical-open-width !important;
            }
            &.fixed-right + .main-content {
                margin-right: $navbar-vertical-open-width !important;
            }
        }
    }
    
    
}

@media (min-width: 1200px) and (max-height: 850px) {
}

@media (min-width: 1200px) and (max-width: 1599px) {
}

@media (min-width: 1200px) and (max-width: 1450px) {
    .profile-list.list-group{
        .row{
            .col{
                flex: 0 0 100%;
                text-align: left !important;
            }
        }
    }

    // .profile-wrap{
    //     .btn-outline-black{
    //         svg{
    //             display: none;
    //         }
    //         font-size: $fs12;
    //     }
    // }
}

@media (min-width: 576px){
    .event_register_modal {
        max-width: 600px;
        margin: 1.75rem auto;
    }

    .profile_qr_modal {
        max-width: 600px;
        margin: 1.75rem auto;
    }
}

// nav bar
@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
        .nav-collapse-container {
            display: flex;
            justify-content: center;
            position: absolute;
            width: 100%;
        }
    }
    .navbar-logo {
        max-width: 230px;
        max-height: 80px;
    }
    .nav-active-bar {
        display: flex;
        background-color: black;
        height: 2px;
        margin-top: 5px;
        width: 100%;
    }
}
@media (max-width: 992px) {
    .nav-collapse-container {
        .navbar-nav {
           flex-direction: column;
           background-color: white;
           .nav-item {
                width: 70%;
                padding-top: 10px;
                padding-bottom: 10px;
           }
           .nav-collapse-navlink-login {
                width: 30%;
                justify-content: flex-end;
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;
           }
        }
    }
    .navbar-logo {
        max-width: 200px;
        max-height: 60px;
    }
    .nav-active-bar {
        display: flex;
        background-color: black;
        height: 2px;
        margin-top: 5px;
        width: 100%;
    }
}
