@import '../../assets/scss/custom/variables';
.footer_container {
    background-color: white;
    padding: 4rem;
    display: flex;
    flex-direction: row;
}

.logo_container {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.content_container {
    flex: 4;
    display: flex;
    flex-direction: row;
}

.information_container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.social_media_container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.newsletter_container {
    flex: 3;
    display: flex;
    flex-direction: column;
}

// logo container
.footer_logo {
    max-width: 235px;
    max-height: 90px;
    object-fit: cover;
    margin-bottom: 1rem;
}

.logo_title {
    color: #101820;
    font-size: $fs14;
}

.logo_title_container {
    display: flex;
    flex-direction: column;
}

// information container
.information_title {
    color: #101820;
    font-size: $fs16;
    font-weight: 600;
    margin-bottom: 5px;
}

.information_item {
    margin-bottom: 5px;
}

.information_item_text {
    color: #101820;
    font-size: $fs14;
}

// social media container
.social_media_title {
    color: #101820;
    font-size: $fs16;
    font-weight: 600;
    margin-bottom: 5px;
}

.social_media_item {
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.social_media_svg {
    width: 20px;
    height: 20px;
}

.social_media_logo_container {
    margin-right: 8px;
    align-items: center;
}

.social_media_logo_ul {
    margin-bottom: 4px;
    padding-left: 0px;
}

.social_media_logo_li {
    list-style: none;
}

// newsletter container
.newsletter_title {
    color: #101820;
    font-size: $fs16;
    font-weight: 600;
    margin-bottom: 5px;
}

.newsletter_desc {
    color: #101820;
    font-size: $fs14;
}

.newsletter_input {
    background-color: rgb(247, 247, 247);
}

// footer
.footer_title {
    color: #101820;
    font-size: $fs14;
}

.enquiry_floater,
.enquiry_floater_mob {
    position: fixed;
    bottom: 0;
    right: 0;
    cursor: pointer;
    z-index: 99999;
}

.enquiry_floater_mob {
    display: none;
}

// responsive
@media (max-width: 1318px) {
    .footer_container {
        padding: 3rem;
        flex-direction: row;
    }
}

@media (max-width: 991px) {
    .footer_container {
        padding: 2rem;
        flex-direction: row;
    }
    .footer_logo {
        max-width: 195px;
        max-height: 50px;
    }
    .enquiry_floater {
        display: none;
    }
    .enquiry_floater_mob {
        display: block;
    }
}

@media (max-width: 767px) {
    .footer_container {
        padding: 2rem;
        flex-direction: column;
    }
    .logo_container {
        margin-bottom: 2rem;
    }
    .content_container {
        margin-bottom: 2rem;
    }
    .footer_logo {
        max-width: 215px;
        max-height: 70px;
    }
    .subscribe_button_container {
        margin-top: 1rem;
    }
}

@media (min-width: 992px) {}